import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationStart, Router } from '@angular/router';
import { cloneDeep, isEqual, isUndefined } from 'lodash-es';
import { BehaviorSubject, startWith } from 'rxjs';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { AuthService } from '../../shared/auth/auth.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { AppsAnalysisMetadataService } from '../apps/apps-analysis-metadata.service';
import { IRecentTrendsPageSettings } from './recent-trends-page-settings';

@Injectable({
  providedIn: 'root'
})
export class RecentTrendsPageSettingsService {

  private availableToplineMetrics: IAttributionMetadataMetricDto[];
  private availableHeadlineMetrics: IAttributionMetadataMetricDto[];
  private availableTimeseriesChartMetrics: IAttributionMetadataMetricDto[];
  private readonly storageKey = 'pageSettings.recentTrends';
  private loading?: Promise<IRecentTrendsPageSettings>;
  private appId: string;

  public constructor(
    private readonly localStorage: LocalStorageService,
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly appsAnalysisMetadataService: AppsAnalysisMetadataService
  ) {
    // Invalidate data when the user's active advertiser changes
    this.auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(() => this.invalidate());

    this.router.events.pipe(filter(event => event instanceof ActivationStart), startWith(this.router))
      .subscribe(async () => {
        const newAppId = this.findAppId(this.router.routerState.snapshot.root);
        if (newAppId !== this.appId) {
          this.appId = newAppId;
          this.invalidate();
        }
      });
  }

  private _settings$ = new BehaviorSubject<IRecentTrendsPageSettings>(undefined);

  // noinspection JSUnusedGlobalSymbols
  public get settings$() {
    if (!this._settings$.value) { this.get(); }
    return this._settings$.asObservable();
  }

  public async get(appId: string = null, ignoreCache = false) {
    if (this.loading) { return this.loading; }
    if (appId) { this.appId = appId; }
    if (!ignoreCache && !isUndefined(this._settings$.value)) { return this._settings$.value; }
    this.loading = new Promise(async resolve => {
      const metrics = (await this.appsAnalysisMetadataService.getById(this.appId))?.metrics || [];

      this.availableToplineMetrics = metrics.filter(x => x.suitableForTopline) || [];
      this.availableHeadlineMetrics = metrics.filter(x => x.suitableForHeadline) || [];
      this.availableTimeseriesChartMetrics = metrics.filter(x => x.suitableForTimeseriesChart) || [];

      const validNamesForToplines = this.availableToplineMetrics.map(x => x.name);
      const validNamesForHeadlines = this.availableHeadlineMetrics.map(x => x.name);
      const validNamesForCharts = this.availableTimeseriesChartMetrics.map(x => x.name);

      const pageSettings = cloneDeep(this.localStorage.get<IRecentTrendsPageSettings>(`${this.storageKey}.${this.appId}`) || {});

      pageSettings.toplineMetrics = pageSettings.toplineMetrics?.filter(x => validNamesForToplines.includes(x)) || [];
      pageSettings.headlineMetrics = pageSettings.headlineMetrics?.filter(x => validNamesForHeadlines.includes(x)) || [];
      pageSettings.timeseriesChartMetrics = pageSettings.timeseriesChartMetrics?.filter(x => validNamesForCharts.includes(x)) || [];

      if (!pageSettings.toplineMetrics.length) {
        pageSettings.toplineMetrics = this.availableToplineMetrics.filter(x => x.defaultForTopline).slice(0, 2).map(x => x.name);
      }

      if (!pageSettings.headlineMetrics.length) {
        pageSettings.headlineMetrics = this.availableHeadlineMetrics.filter(x => x.defaultForHeadline).slice(0, 3).map(x => x.name);
      }

      if (!pageSettings.timeseriesChartMetrics.length) {
        pageSettings.timeseriesChartMetrics = this.availableTimeseriesChartMetrics.filter(x => x.defaultForTimeseriesChart).map(x => x.name);
      }

      this._settings$.next(pageSettings);
      resolve(pageSettings);
      this.loading = undefined;
    });
    return this.loading;
  }

  public async getAvailableToplineMetrics() {
    await this.get();
    return this.availableToplineMetrics || [];
  };

  public async getAvailableHeadlineMetrics() {
    await this.get();
    return this.availableHeadlineMetrics || [];
  };

  public async getAvailableTimeseriesChartMetrics() {
    await this.get();
    return this.availableTimeseriesChartMetrics || [];
  };

  public update(settings: IRecentTrendsPageSettings) {
    if (isEqual(this._settings$.value, settings)) { return; }
    this.localStorage.set(`${this.storageKey}.${this.appId}`, settings);
    this._settings$.next(cloneDeep(settings));
  }

  public reset() {
    this.localStorage.remove(`${this.storageKey}.${this.appId}`);
    const settings = {
      toplineMetrics: this.availableToplineMetrics.filter(x => x.defaultForTopline).slice(0, 2).map(x => x.name),
      headlineMetrics: this.availableHeadlineMetrics.filter(x => x.defaultForHeadline).slice(0, 3).map(x => x.name),
      timeseriesChartMetrics: this.availableTimeseriesChartMetrics.filter(x => x.defaultForTimeseriesChart).map(x => x.name)
    } as IRecentTrendsPageSettings;
    this._settings$.next(settings);
  }

  public invalidate() {
    // If we have no subscribers, just invalidate the observable so that the next subscription will cause the data to be re-requested - otherwise re-request the data
    if (!this._settings$?.observed) { this._settings$?.next(undefined); } else { this.get('', true); }
  }

  private findAppId(snapshot: ActivatedRouteSnapshot): string {
    if (snapshot?.params?.appId) { return snapshot?.params?.appId; }
    for (let c of snapshot.children) {
      if (c.params?.appId) { return c.params?.appId; }
      const childAppId = this.findAppId(c);
      if (childAppId) { return childAppId; }
    }
    return '';
  }

}
