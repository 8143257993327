import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-network-drilldown-tabs',
  templateUrl: './network-drilldown-tabs.component.html',
  styleUrls: ['./network-drilldown-tabs.component.scss']
})
export class NetworkDrilldownTabsComponent implements OnInit, OnDestroy {

  private routeParamsSubscription: Subscription;

  public constructor(private readonly route: ActivatedRoute, public readonly router: Router) { }

  private _appId: string;

  public get appId() { return this._appId; }

  private _networkId: string;

  public get networkId() { return this._networkId; }

  public get networksTabIsActive() {
    return !this.router.routerState.snapshot.url.includes('publishers') && !this.router.routerState.snapshot.url.includes('campaigns');
  }

  public ngOnInit(): void {
    this.routeParamsSubscription = this.route.params.subscribe(p => {
      this._appId = p.appId;
      this._networkId = p.networkId;
    });
  }

  public ngOnDestroy(): void {
    this.routeParamsSubscription?.unsubscribe();
  }

}
