import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationStart, Router } from '@angular/router';
import { cloneDeep, first, isEqual, isUndefined } from 'lodash-es';
import { BehaviorSubject, startWith } from 'rxjs';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { AuthService } from '../../shared/auth/auth.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { AppsAnalysisMetadataService } from '../apps/apps-analysis-metadata.service';
import { IComparePageSettings } from './compare-page-settings';

@Injectable({
  providedIn: 'root'
})
export class ComparePageSettingsService {

  private availableColumnMetrics: IAttributionMetadataMetricDto[];
  private availableBenefitMetrics: IAttributionMetadataMetricDto[];
  private loading?: Promise<IComparePageSettings>;
  private readonly storageKey = 'pageSettings.compare';
  private appId: string;

  public constructor(
    private readonly localStorage: LocalStorageService,
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly appsAnalysisMetadataService: AppsAnalysisMetadataService
  ) {
    // Invalidate data when the user's active advertiser changes
    this.auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(() => this.invalidate());

    this.router.events.pipe(filter(event => event instanceof ActivationStart), startWith(this.router))
      .subscribe(async () => {
        this.appId = this.findAppId(this.router.routerState.snapshot.root);
        this.invalidate();
      });
  }

  private _settings$ = new BehaviorSubject<IComparePageSettings>(undefined);

  // noinspection JSUnusedGlobalSymbols
  public get settings$() {
    if (!this._settings$.value) { this.get(); }
    return this._settings$.asObservable();
  }

  public async get(appId: string = null, ignoreCache = false) {
    if (this.loading) { return this.loading; }
    if (appId) { this.appId = appId; }

    // ToDo: Refactor the entire logic of page settings and their change events
    // if (!ignoreCache && !isUndefined(this._settings$.value)) { return this._settings$.value; }

    this.loading = new Promise(async resolve => {
      const metrics = (await this.appsAnalysisMetadataService.getById(this.appId))?.metrics || [];

      this.availableColumnMetrics = metrics.filter(x => x.suitableForComparisonTableColumn) || [];
      this.availableBenefitMetrics = metrics.filter(x => x.suitableForSpendBenefitComparison) || [];

      const validColumnMetricNames = this.availableColumnMetrics.map(x => x.name);

      const pageSettings = cloneDeep(this.localStorage.get<IComparePageSettings>(`${this.storageKey}.${this.appId}`) || {} as IComparePageSettings);
      pageSettings.columnMetrics = pageSettings.columnMetrics?.filter((x: string) => validColumnMetricNames.includes(x)) || [];

      if (!pageSettings.columnMetrics.length) {
        pageSettings.columnMetrics = this.availableColumnMetrics.filter(x => x.defaultForComparisonTableColumn).map(x => x.name);
      }

      pageSettings.columnMetrics = this.getOrderedMetrics(pageSettings.columnMetrics);

      if (!pageSettings.benefitMetric || !this.availableBenefitMetrics.some(x => x.name === pageSettings.benefitMetric)) {
        pageSettings.benefitMetric = this.availableBenefitMetrics.find(x => x.defaultForSpendBenefitComparison)?.name || first(this.availableBenefitMetrics)?.name;
      }

      this._settings$.next(pageSettings);
      resolve(pageSettings);
      this.loading = undefined;
    });
    return this.loading;
  }

  public getOrderedMetrics(metrics: string[]) {
    const ordered = []
    metrics.forEach(m => {
      if (m === 'spend') {
        ordered.unshift(m)
      } else {
        ordered.push(m)
      }
    });
    return ordered;
  }

  public async getAvailableColumnMetrics() {
    await this.get();
    return this.availableColumnMetrics || [];
  };

  public async getAvailableBenefitMetrics() {
    await this.get();
    return this.availableBenefitMetrics || [];
  };

  public update(settings: IComparePageSettings) {
    if (isEqual(this._settings$.value, settings)) { return; }
    this.localStorage.set(`${this.storageKey}.${this.appId}`, settings);
    this._settings$.next(cloneDeep(settings));
  }

  public reset() {
    this.localStorage.remove(`${this.storageKey}.${this.appId}`);
    const settings = {
      timeseriesChartMetrics: this.availableColumnMetrics?.filter(x => x.defaultForComparisonTableColumn).map(x => x.name) || [],
      benefitMetric: this.availableBenefitMetrics.find(x => x.defaultForSpendBenefitComparison)?.name || first(this.availableBenefitMetrics)?.name
    } as IComparePageSettings;
    this._settings$.next(settings);
  }

  public invalidate() {
    // If we have no subscribers, just invalidate the observable so that the next subscription will cause the data to be re-requested - otherwise re-request the data
    if (!this._settings$?.observed) { this._settings$?.next(undefined); } else { this.get('', true); }
  }

  private findAppId(snapshot: ActivatedRouteSnapshot): string {
    if (snapshot?.params?.appId) { return snapshot?.params?.appId; }
    for (let c of snapshot.children) {
      if (c.params?.appId) { return c.params?.appId; }
      const childAppId = this.findAppId(c);
      if (childAppId) { return childAppId; }
    }
    return '';
  }

}
