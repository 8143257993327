<app-page-header title="Analysis">
  <div class="right-content">
    <app-app-selector></app-app-selector>
  </div>
</app-page-header>
<app-analysis-tabs></app-analysis-tabs>
<app-analysis-settings [showNetwork]="false" [showRegion]="false" [showCadence]="true"
  [showDateRange]="false"></app-analysis-settings>

<section class="page-area">
  <div class="padded-content top-bottom-padding" *ngIf="loadingComplete">
    <app-content-header [iconUrls]="iconUrls" [title]="titleBreadcrumbs" helpArticleId="34489106267161">
      <div class="right-content btn-download">
        <a (click)="export()"><i class="fa-solid fa-download page-settings"></i></a>
      </div>
      <app-compare-page-settings class="right-content"></app-compare-page-settings>
    </app-content-header>
    <div class="recommendation-chart-wrapper">
      <highcharts-chart [Highcharts]="Highcharts" [options]="recommendationsLineChart" class="recommendation-line-chart"
        oneToOne="true"></highcharts-chart>
      <highcharts-chart [Highcharts]="Highcharts" [options]="recommendationsBarChart" class="recommendation-bar-chart"
        oneToOne="true" runOutsideAngular="true"></highcharts-chart>
    </div>
    <div class="recommendation-grid-wrapper">
      <app-compare-data-table>
        <app-data-table>
          <table class="dynamic-columns">
            <thead>
              <tr>
                <th [attr.colspan]="columnMetrics.length+1" class="networks-grid-header">
                  {{selectedRecommendationDateLabel}}
                </th>
              </tr>
              <tr>
                <th class="left-line">
                  <a (click)="toggleSort('name', SortOrder.Ascending)" *ngIf="canSort"
                    class="{{sortClass('name')}}">Networks</a>
                  <strong *ngIf="!canSort">Network</strong>
                </th>
                <th *ngFor="let metric of columnMetrics" class="left-line">
                  <a (click)="toggleSort(metric.name, SortOrder.Descending)" *ngIf="canSort"
                    [tooltip]="metric.description" class="{{sortClass(metric.name)}}" container="body"
                    delay="500">{{metric.shortDisplayName || metric.displayName}}</a>
                  <strong *ngIf="!canSort" [tooltip]="metric.description" container="body"
                    delay="500">{{metric.shortDisplayName || metric.displayName}}</strong>
                    <div class="grid-header-sub-label">(Recommended)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="network-row" *ngFor="let row of rows">
                <!-- NAME -->
                <!-- temporary check to prevent click event if metric does not have any spending -->
                <td [copyable]="false"
                  [icon]="hasPerspectiveMetadata ? row.description.iconUrlSmall || row.description.iconUrl || PerspectiveDefaultIcon[0] : null"
                  [label]="row.description.displayName" class="mixed-case no-word-break" firstCell>
                </td>
                <!-- METRICS -->
                <td *ngFor="let metric of row?.analysis?.metrics" [class.center]="metric.config.name !== 'spend'"
                  [class.two-values]="metric.config.name === 'spend'" class="left-line">
                  <div *ngIf="metric.config.name === 'spend'">
                    <span *ngIf="!metric.current" class="no-ad-spend-label">No ad spend <br /> available</span>
                    <app-metric-current-value *ngIf="metric.current" [config]="metric.config"
                      [value]="metric.current"></app-metric-current-value>
                  </div>
                  <div *ngIf="metric.config.name !== 'spend'" class="average-val-with-deviation">
                    <app-metric-current-value *ngIf="metric.current" [config]="metric.config" [value]="metric.current"
                      class="current-value"></app-metric-current-value>
                    <span class="current-deviation">
                      <span *ngIf="metric.currentDeviation" class="sign">&plusmn;</span>
                      <app-metric-current-value class="value-deviation" *ngIf="metric.currentDeviation"
                        [config]="metric.config" [value]="metric.currentDeviation"></app-metric-current-value>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot *ngIf="morePagesAvailable || !rows?.length">
              <tr *ngIf="rows?.length && morePagesAvailable">
                <td [colSpan]="2 + columnMetrics.length">
                  <div class="pagination-row">
                    <p class="note">
                      <i class="fa-solid fa-circle-info"></i>
                      <span>Column sorting will be enabled when all the data is loaded</span>
                    </p>
                    <div class="main">
                      <p class="pagination-info">Showing {{rows.length | number: '1.0-0'}} of {{availableRows | number:
                        '1.0-0'}}</p>
                      <button (click)="loadNextPage()" class="btn btn-secondary">Load more</button>
                    </div>
                    <div class="spacer"></div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="!rows?.length">
                <td [colSpan]="2 + columnMetrics.length" class="no-data">NO DATA</td>
              </tr>
            </tfoot>
          </table>
        </app-data-table>
      </app-compare-data-table>
    </div>
  </div>
</section>