import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-percent-with-tooltip',
  templateUrl: './percent-with-tooltip.component.html',
  styleUrls: ['./percent-with-tooltip.component.scss']
})
export class PercentWithTooltipComponent {

  @Input() value: string | number;

  @Input() digitsInfo = '1.0-0';

  @Input() tooltipDigitsInfo = '1.0-1';

}
