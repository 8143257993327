<span class="label">Switch App: </span>
<ng-select (change)="selectApp($event)" [(ngModel)]="appId" [clearable]="false" [hideSelected]="true" [items]="apps" [searchable]="false" appendTo="body" bindLabel="displayName" bindValue="id"
           id="appId" placeholder="Change App">
  <ng-template let-app="item" ng-label-tmp>
    <ng-container *ngIf="asIAppDto(app); let app">
      <div *ngIf="app?.displayName" class="text-with-icon">
        <img [alt]="app.displayName" [src]="iconUrl(app)" class="icon size-24" />
        <img [alt]="app.displayName" [src]="app.operatingSystem | osIcon" class="icon size-24" />
        <span class="name">{{app.displayName}}</span>
      </div>
    </ng-container>
  </ng-template>
  <ng-template let-app="item" ng-option-tmp>
    <ng-container *ngIf="asIAppDto(app); let app">
      <div *ngIf="app?.displayName" class="text-with-icon">
        <img [alt]="app.displayName" [src]="iconUrl(app)" class="icon size-20" />
        <img [alt]="app.displayName" [src]="app.operatingSystem | osIcon" class="icon size-20" />
        <span class="name">{{app.displayName}}</span>
      </div>
    </ng-container>
  </ng-template>
</ng-select>
