import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isEqual, isNil } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectComponent),
    multi: true
  }],
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements ControlValueAccessor {

  // tslint:disable-next-line: rxjs-no-exposed-subjects
  @Input() public typeahead: Subject<string>;
  @Input() public maxTokens = 3;
  @Input() public disabled = false;
  @Input() public bindLabel = 'name';
  @Input() public bindToken = 'token';
  @Input() public clearable = true;
  @Input() public clearSearchOnAdd = true;
  @Input() public searchable = true;
  @Input() public groupBy: string;
  @Input() public bindValue: string;
  @Input() public appendTo: string;
  @Input() public compareValue: string;
  @Input() public value: any[];
  @Input() public loading: boolean;
  @Input() public class: string;
  @ViewChild('ngSelectControl', { static: false }) private _ngSelect: NgSelectComponent;

  private _items: Promise<any[]> | Observable<any[]>;

  @Input()
  public get items(): any[] | Promise<any[]> | Observable<any[]> { return this._items; }

  public set items(value: any[] | Promise<any[]> | Observable<any[]>) { this._items = Array.isArray(value) ? Promise.resolve(value) : value; }

  public compareWith = (a: any, b: any) => {
    if (this.compareValue) {
      return a[this.compareValue] === b;
    } else {
      return isEqual(a[this.bindValue], b);
    }
  };

  public async selectAll() {
    if (isNil(this.items)) { return; }

    if (this.items instanceof Observable) {
      this.items.pipe(take(1)).subscribe(x => {
        this.value = x.map(p => this.bindValue ? p[this.bindValue] : p);
        this.onChange(this.value);
      });
      return;
    }

    const items = this.items instanceof Promise ? (await this.items) : this.items;
    this.value = items.map(p => this.bindValue ? p[this.bindValue] : p);
    this.onChange(this.value);
  }

  public focus() {
    if (this.class) { setTimeout(() => document.querySelector('.ng-dropdown-panel')?.classList?.add(this.class), 0); }
  }

  public writeValue(value: any): void { this.value = value; }

  public registerOnChange(fn: any): void { this.onChange = fn; }

  public registerOnTouched(fn: any): void { this.onTouched = fn; }

  public setDisabledState?(isDisabled: boolean): void { this._ngSelect?.setDisabledState(isDisabled); }

  // noinspection JSUnusedLocalSymbols
  public onChange = (value: any) => {};

  // noinspection JSUnusedLocalSymbols
  public onTouched = (value: any) => {};

}
