<app-page-secondary-tabs>
  <ol class="tab-links">
    <li><a [class.active]="isCompare" [routerLink]="['/analysis', 'apps', appId, SpendOptimisationPerspectiveSlug[perspective]]">Compare</a></li>
    <li *ngIf="unpinnedId" class="unpinned">
      <a [routerLink]="['/analysis', 'apps', appId, SpendOptimisationPerspectiveSlug[perspective], unpinnedId]" routerLinkActive="active">{{displayName(unpinnedId)}}</a>
      <button (click)="pin(unpinnedId)"><i class="fa fa-thumbtack"></i></button>
    </li>
    <li *ngFor="let id of pins; let i = index" [class.first]="i === 0" class="pin">
      <a [routerLink]="['/analysis', 'apps', appId, SpendOptimisationPerspectiveSlug[perspective], id].concat(childRouteSegments)" routerLinkActive="active">{{displayName(id)}}</a>
      <button (click)="unpin(id)"><i class="fa-solid fa-times"></i></button>
    </li>
    <li class="add">
      <div class="btn-group" container="body" dropdown>
        <button aria-controls="add-pin-dropdown" class="btn btn-secondary dropdown-toggle" dropdownToggle id="add-pin-button" type="button"><i class="fa fa-plus-circle"></i></button>
        <ul *dropdownMenu aria-labelledby="add-pin-button" class="dropdown-menu" id="add-pin-dropdown" role="menu">
          <li *ngFor="let i of allUnpinned" role="menuitem"><a (click)="pin(i.id)" class="dropdown-item">{{displayName(i.id)}}</a></li>
        </ul>
      </div>
    </li>
    <li class="right">
      <ng-content select=".right-content"></ng-content>
    </li>
  </ol>
</app-page-secondary-tabs>
