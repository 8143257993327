import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blade-tabs',
  templateUrl: './blade-tabs.component.html',
  styleUrls: ['./blade-tabs.component.scss']
})
export class BladeTabsComponent implements OnInit {

  public constructor() { }

  public ngOnInit() {
  }

}
