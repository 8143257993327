import { Injectable } from '@angular/core';
import { IComparisonDto, IComparisonRowDto } from '../../api-model/comparison-dto';
import { first, flatMap, groupBy, keyBy, mapValues, orderBy, uniq, uniqBy } from 'lodash-es';
import { AttributionQueryHelperService } from 'src/app/shared/services/attribution-query-helper.service';
import { AnalysisSettingsService } from '../shared/analysis-settings.service';
import { firstValueFrom } from 'rxjs';
import { AppsAnalysisMetadataService } from '../apps/apps-analysis-metadata.service';
import { HttpClient } from '@angular/common/http';
import { NetworksDataService } from 'src/app/networks/networks-data.service';
import { IAttributionMetricsResultDto } from 'src/app/api-model/attribution-metrics-response-dto';
import { IRecommendationMetricDto } from 'src/app/api-model/recommendation-dto';
import { Cadence } from 'src/app/api-model/enums/cadence';
import { ComparePageSettingsService } from '../shared/compare-page-settings.service';
import { DateRange } from 'src/app/shared/date-range';

@Injectable({
  providedIn: 'root'
})
export class RecommendationsDataService {
  public constructor(
    private readonly http: HttpClient,
    private readonly queryHelper: AttributionQueryHelperService,
    private readonly analysisSettings: AnalysisSettingsService,
    private readonly appsAnalysisMetadataService: AppsAnalysisMetadataService,
    private readonly networksDataService: NetworksDataService,
    private readonly comparePageSettingsService: ComparePageSettingsService
  ) { }

  public async getOptimizedSpends(appId: string): Promise<IRecommendationMetricDto[]> {
    const app = await this.appsAnalysisMetadataService.getById(appId);
    if (!app) { return null; }

    const metrics = app.metrics.filter(x => x.suitableForSummablePrimaryGoal);
    const spendMetric = app.metrics.find(x => x.name === 'spend');
    metrics.push(spendMetric);

    const cadenceOptions = this.queryHelper.getQueryOptionsForCadence(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    const projection = [`spend (transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`];

    const result = await firstValueFrom(this.http.post<any>(app.endpoint, {
      query: `query {
        byNetwork: potential(options: {${cadenceOptions.recommendationDates}${cadenceOptions.periodicity}}) {
          results { date networkId ${projection.join(' ')} }
        }
      }`
    }));
    const attrs = groupBy(result?.data?.byNetwork.results as IAttributionMetricsResultDto[], x => x.date.toISOString()) as {
      [date: string]: IAttributionMetricsResultDto[]
    };
    const timeseries = uniq(Object.keys(attrs)).sort().map(date => ({
      date: first(attrs[date])?.date,
      byNetwork: keyBy(attrs[date], x => x.networkId),
    }));

    const networks = await this.networksDataService.get();
    const recommendations = uniqBy((result?.data?.byNetwork?.results || []) as IAttributionMetricsResultDto[], x => x.networkId).map(x => ({
      name: networks.find(n => n.id === x.networkId)?.displayName || x.networkName,
      network: networks.find(n => n.id === x.networkId) || {
        id: x.networkId,
        displayName: x.networkName
      },
      metrics: mapValues(keyBy(metrics, x => x.name), m => timeseries.map(t => ({
        date: t.date,
        value: (t.byNetwork[x.networkId] || {})[m.name] || 0
      })))
    } as IRecommendationMetricDto)).filter(x => Object.values(x.metrics).some(m => m.some(d => d.value)));

    return orderBy(recommendations, [x => !x.network, x => x.isNaturalDemand]);
  }

  public async getRecentSpends(appId: string): Promise<IRecommendationMetricDto[]> {
    const app = await this.appsAnalysisMetadataService.getById(appId);
    if (!app) { return null; }

    const metrics = app.metrics.filter(x => x.suitableForSummablePrimaryGoal);
    const spendMetric = app.metrics.find(x => x.name === 'spend');
    metrics.push(spendMetric);

    const cadenceOptions = this.queryHelper.getQueryOptionsForCadence(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    const projection = [`spend (transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`];

    const result = await firstValueFrom(this.http.post<any>(app.endpoint, {
      query: `query {
        byNetwork: attribution(options: {${cadenceOptions.recommendationRecentDates}${cadenceOptions.periodicity}}) {
          results { date networkId ${projection.join(' ')} }
        }
      }`
    }));
    const attrs = groupBy(result?.data?.byNetwork.results as IAttributionMetricsResultDto[], x => x.date.toISOString()) as {
      [date: string]: IAttributionMetricsResultDto[]
    };

    const timeseries = uniq(Object.keys(attrs)).sort().map(date => ({
      date: first(attrs[date])?.date,
      byNetwork: keyBy(attrs[date], x => x.networkId),
    }));

    const networks = await this.networksDataService.get();

    const recommendations = uniqBy((result?.data?.byNetwork.results || []) as IAttributionMetricsResultDto[], x => x.networkId).map(x => ({
      name: networks.find(n => n.id === x.networkId)?.displayName || x.networkName,
      network: networks.find(n => n.id === x.networkId) || {
        id: x.networkId,
        displayName: x.networkName
      },
      metrics: mapValues(keyBy(metrics, x => x.name), m => timeseries.map(t => ({
        date: t.date,
        value: (t.byNetwork[x.networkId] || {})[m.name] || 0
      })))
    } as IRecommendationMetricDto)).filter(x => Object.values(x.metrics).some(m => m.some(d => d.value)));

    return orderBy(recommendations, [x => !x.network, x => x.isNaturalDemand]);
  }

  public async getPredictedSpends(appId: string): Promise<IRecommendationMetricDto[]> {
    const app = await this.appsAnalysisMetadataService.getById(appId);
    if (!app) { return null; }

    const metrics = app.metrics.filter(x => x.suitableForSummablePrimaryGoal);
    const spendMetric = app.metrics.find(x => x.name === 'spend');
    metrics.push(spendMetric);

    const cadenceOptions = this.queryHelper.getQueryOptionsForCadence(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    const projection = [`spend (transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`];

    const result = await firstValueFrom(this.http.post<any>(app.endpoint, {
      query: `query {
        byNetwork: attribution(options: {${cadenceOptions.recommendationDates}${cadenceOptions.periodicity}}) {
          results { date networkId ${projection.join(' ')} }
        }
      }`
    }));

    const attrs = groupBy(result?.data?.byNetwork.results as IAttributionMetricsResultDto[], x => x.date.toISOString()) as {
      [date: string]: IAttributionMetricsResultDto[]
    };

    const timeseries = uniq(Object.keys(attrs)).sort().map(date => ({
      date: first(attrs[date])?.date,
      byNetwork: keyBy(attrs[date], x => x.networkId),
    }));

    const networks = await this.networksDataService.get();

    const recommendations = uniqBy((result?.data?.byNetwork.results || []) as IAttributionMetricsResultDto[], x => x.networkId).map(x => ({
      name: networks.find(n => n.id === x.networkId)?.displayName || x.networkName,
      network: networks.find(n => n.id === x.networkId) || {
        id: x.networkId,
        displayName: x.networkName
      },
      metrics: mapValues(keyBy(metrics, x => x.name), m => timeseries.map(t => ({
        date: t.date,
        value: (t.byNetwork[x.networkId] || {})[m.name] || 0
      })))
    } as IRecommendationMetricDto)).filter(x => Object.values(x.metrics).some(m => m.some(d => d.value)));

    return orderBy(recommendations, [x => !x.network, x => x.isNaturalDemand]);
  }

  public async getNetworkMetrics(options: {
    appId: string;
    idField: keyof IAttributionMetricsResultDto;
    sortField: string;
    dateRange: DateRange;
  }): Promise<IComparisonDto> {
    const app = await this.appsAnalysisMetadataService.getById(options.appId);
    if (!app) { return { rows: [], pageNumber: 1, availablePages: 1, availableRows: 0 }; }

    const settings = await this.comparePageSettingsService.get(options.appId);
    const metricNames = settings.columnMetrics.concat(settings.benefitMetric);
    const metrics = app.metrics.filter(x => metricNames.includes(x.name) || x.name === 'spend');

    const cadenceOptions = this.queryHelper.getQueryOptionsForCadence(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    const queryDates = `start: "${options.dateRange.start.format('YYYY-MM-DD')}" end: "${options.dateRange.end.format('YYYY-MM-DD')}"`;

    const projection = flatMap(metrics.map(x => x.ranged
      ? [
        `${x.name}Min(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`,
        `${x.name}Max(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`
      ]
      : `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`))

    const result = await firstValueFrom(this.http.post<any>(app.endpoint, {
      query: `query {
        attribution: potential(options: {${queryDates}${cadenceOptions.periodicity}}) {
          results { ${options.idField} date ${projection.join(' ')} }
        }
      }`
    }));

    let mostRecent: Date;
    switch (this.analysisSettings.cadence) {
      case Cadence.Weekly:
        mostRecent = options.dateRange.end.startOf('isoWeek').toDate();
        break;
      case Cadence.Monthly:
        mostRecent = options.dateRange.end.startOf('month').toDate();
        break;
      default:
        mostRecent = options.dateRange.end.startOf('day').toDate();
        break;
    }

    await this.addAttributionForMostRecentPeriod(result?.data?.attribution.results as IAttributionMetricsResultDto[], mostRecent);

    const attributionById = groupBy((result?.data?.attribution.results as IAttributionMetricsResultDto[]).filter(x => x.date.setHours(0, 0, 0) <= mostRecent.setHours(0, 0, 0)), x => x[options.idField]);
    return {
      rows: Object.entries(attributionById).map(([id, attribution]) => {
        const mappedMetrics = metrics.map(x => this.queryHelper.mapPotentialMetric(attribution, x, this.analysisSettings.cadence, options.dateRange));
        return ({
          id: id,
          metrics: mappedMetrics,
        } as IComparisonRowDto);
      }), //filter(r => r.metrics.some(m => m.config.name == 'spend' && m.current)),
      pageNumber: 1,
      availablePages: 1,
      availableRows: 0
    };
  }

  private async addAttributionForMostRecentPeriod(results: IAttributionMetricsResultDto[], mostRecent: Date) {
    const lastPeriodEntries = []
    const nwIds = results.map(r => r.networkId).filter((v, i, a) => a.indexOf(v) == i);

    nwIds.forEach(nwid => {
      const attr = results.find(r => r.networkId === nwid && new Date(r.date).setHours(0, 0, 0) === mostRecent.setHours(0, 0, 0));
      if (!attr) {
        lastPeriodEntries.push({
          networkId: nwid,
          date: mostRecent,
          cpa: 0,
          spend: 0,
          paidRevenue365d: 0,
          cpi: 0,
          roas: 0
        })
      }
    });
    lastPeriodEntries.forEach(e => {
      results.push(e)
    });
  }
}
