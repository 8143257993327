<app-page-header title="Analysis">
  <div class="right-content">
    <app-app-selector></app-app-selector>
  </div>
</app-page-header>
<app-analysis-tabs></app-analysis-tabs>
<app-compare-tabs *ngIf="showCompareTabs">
</app-compare-tabs>
<app-network-drilldown-tabs *ngIf="showNetworkDrilldownTabs"></app-network-drilldown-tabs>
<app-analysis-settings [showNetwork]="showNetworkFilter" [showRegion]="showRegionFilter"></app-analysis-settings>

<section class="page-area">
  <div class="padded-content top-bottom-padding">
    <app-content-header [iconUrls]="iconUrls" [title]="titleBreadcrumbs">
      <app-raw-data-page-settings class="right-content"></app-raw-data-page-settings>
    </app-content-header>
    <app-drilldown-tabs></app-drilldown-tabs>

    <app-compare-data-table>
      <app-data-table>
        <table class="dynamic-columns">
          <thead>
            <tr>
              <th>Date</th>
              <th *ngFor="let metric of metrics" class="left-line">{{metric.shortDisplayName || metric.displayName}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of rows" [class.emphasis]="row.predicted" [class.today]="row.today">
              <td [copyable]="false" [label]="row.date | cadenceDate" firstCell></td>
              <td *ngFor="let metric of row.metrics" class="left-line">{{metric.value | metricValue:metric.config:{ expand: true } }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr *ngIf="!rows?.length">
              <td [colSpan]="metrics.length + 1" class="no-data">NO DATA</td>
            </tr>
          </tfoot>
        </table>
      </app-data-table>
    </app-compare-data-table>
  </div>
</section>
