import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Resolve, Router } from '@angular/router';
import { isEqual, orderBy } from 'lodash-es';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { IAppDto } from '../../api-model/app-dto';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { IDrilldownDto } from '../../api-model/drilldown-dto';
import { INetworkDto } from '../../api-model/network-dto';
import { ITrendDateDto } from '../../api-model/trend-date-dto';
import { AuthService } from '../../shared/auth/auth.service';
import { OsIconPipe } from '../../shared/pipes/os-icon.pipe';
import { AnalysisSettingsService } from './analysis-settings.service';
import { Perspective, PerspectiveSingularDesc } from './perspective';
import { IPerspectiveMetadata } from './perspective-metadata';
import { RawDataPageSettingsService } from './raw-data-page-settings.service';

@Component({
  selector: 'app-raw-data',
  templateUrl: './raw-data.component.html',
  styleUrls: ['./raw-data.component.scss']
})
export class RawDataComponent implements OnInit, OnDestroy {

  public perspectiveId: string;
  public perspective: Perspective;
  public perspectiveMetadata?: IPerspectiveMetadata;
  public analysis: IDrilldownDto;
  public app: IAppDto;
  public network?: INetworkDto;
  public rows: {
    date: Date;
    predicted: boolean;
    today: boolean;
    metrics: {
      value: number;
      config: IAttributionMetadataMetricDto;
    }[];
  }[] = [];
  public metrics: IAttributionMetadataMetricDto[] = [];

  private activeAdvertiserSubscription: Subscription;
  private routeDataSubscription?: Subscription;
  private pageSettingsSubscription?: Subscription;
  private settingsSubscription: Subscription;
  private analysisResolver: Resolve<IDrilldownDto>;

  public constructor(
    public readonly title: Title,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly analysisSettings: AnalysisSettingsService,
    private readonly osIcon: OsIconPipe,
    private readonly pageSettingsService: RawDataPageSettingsService
  ) {
    this.analysisResolver = inject(this.route.snapshot.routeConfig.resolve.analysis) as Resolve<IDrilldownDto>;
    this.perspectiveId = route.snapshot.params.networkId || route.snapshot.params.regionId;
    this.title.setTitle(`${PerspectiveSingularDesc[this.perspective]} > Raw Data`);
  }

  public get Perspective() { return Perspective; }

  public get iconUrls() {
    return [
      this.app?.iconUrlSmall,
      this.osIcon.transform(this.app?.operatingSystem),
      this.network?.iconUrlSmall,
      this.perspectiveMetadata?.iconUrlSmall,
      this.perspectiveMetadata?.iconUrl
    ].filter(x => x);
  }

  public get titleBreadcrumbs() {
    const crumbs = [this.app?.displayName];
    if (this.network) { crumbs.push(this.network?.displayName); }
    crumbs.push(this.perspectiveMetadata?.displayName || this.analysis?.id, 'Raw Data');
    return crumbs.filter(x => x);
  }

  public get showCompareTabs() { return this.perspective !== Perspective.Publishers && this.perspective !== Perspective.Campaigns; }

  public get showNetworkDrilldownTabs() { return this.perspective === Perspective.Networks || this.perspective === Perspective.Publishers || this.perspective === Perspective.Campaigns; }

  public get showRegionFilter() { return this.perspective !== Perspective.Regions; }

  public get showNetworkFilter() { return this.perspective === Perspective.Regions; }

  public ngOnInit(): void {
    this.activeAdvertiserSubscription = this.auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(async () => {
      // When the user's active advertiser changes, redirect back to the dashboard
      this.router.navigate(['/']);
    });

    this.pageSettingsSubscription = this.pageSettingsService.settings$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(async () => this.loadData(true));

    this.routeDataSubscription = this.route.data.subscribe(d => {
      this.perspectiveId = this.route.snapshot.params.networkId || this.route.snapshot.params.regionId;
      this.perspective = d.perspective;
      this.perspectiveMetadata = (d.perspectiveMetadata as IPerspectiveMetadata[])?.find(x => x.id === this.perspectiveId);
      this.app = d.app;
      this.network = d.network;
      this.analysis = d.analysis;
      this.title.setTitle(`${this.perspectiveMetadata?.displayName || PerspectiveSingularDesc[this.perspective]} > Raw Data`);

      this.settingsSubscription?.unsubscribe();
      this.analysisSettings.settings$.pipe(skip(1), distinctUntilChanged((x, y) =>
        isEqual(x.regionId, y.regionId) && 
        isEqual(x.networkId, y.networkId) && 
        isEqual(x.cadence, y.cadence) && 
        isEqual(x.smoothingPeriod, y.smoothingPeriod) &&
        isEqual(x.dateRange, y.dateRange)))
        .subscribe(async () => this.loadData(true));

      this.loadData();
    });
  }

  public ngOnDestroy(): void {
    this.activeAdvertiserSubscription?.unsubscribe();
    this.pageSettingsSubscription?.unsubscribe();
    this.routeDataSubscription?.unsubscribe();
    this.settingsSubscription?.unsubscribe();
  }

  public async loadData(reloadAnalysis = false) {
    if (reloadAnalysis) {
      this.analysis = await this.analysisResolver.resolve(this.route.snapshot, this.router.routerState.snapshot) as IDrilldownDto;
    }
    await this.composeRows();
  }

  private async composeRows() {
    if (!this.analysis) { return; }
    const settings = await this.pageSettingsService.get();
    const columnMetrics = settings.columnMetrics;
    this.metrics = orderBy(this.analysis.metrics.map(x => x.config).filter(x => columnMetrics.includes(x.name)) || [], x => columnMetrics.findIndex(m => m === x.name));
    this.rows = this.analysis.metrics[0].trend.map((x: ITrendDateDto, i: number) => ({
      date: x.date,
      predicted: moment.utc(x.date).valueOf() >= moment.utc().startOf('day').valueOf(),
      today: moment.utc(x.date).valueOf() == moment.utc().startOf('day').valueOf(),
      metrics: this.metrics.map(m => ({
        value: this.analysis.metrics.find(a => a.config.name === m.name).trend[i]?.value,
        config: m
      }))
    })).reverse();
  }

}
