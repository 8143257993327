import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MessageBoxOutcome } from './message-box-outcome';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent {

  public title: string;

  public message: string;

  public buttons: MessageBoxOutcome[];

  public iconClass: string;

  public onClose: (outcome: MessageBoxOutcome) => void;

  private outcome: MessageBoxOutcome;

  public constructor(public readonly modalRef: BsModalRef, private readonly modalService: BsModalService) {
    modalService.onHide
      .pipe(take(1))
      .subscribe(() => {
        if (this.onClose) { this.onClose(this.outcome || null); }
      });
  }

  public close(outcome: MessageBoxOutcome) {
    this.outcome = outcome;
    this.modalRef.hide();
  }

}
