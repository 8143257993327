<!--suppress JSUnusedGlobalSymbols -->
<app-blade #blade [subTitle]='subTitle' [title]='title' [wide]='true'>
  <div class='history-container'>
    <div *ngFor='let h of history' class='history-item'>
      <header>
        <h4>{{h.description}}</h4>
        <a class='user' href='mailto:{{h.userEmail}}'>{{h.userName}}</a>
        <span class='date'>{{h.date | formattedDate:'dm':'m'}}</span>
      </header>
      <div *ngIf='h.model && historyModelTemplate' class='model-template-wrap'>
        <ng-template [ngTemplateOutletContext]='{ $implicit: h.model }' [ngTemplateOutlet]='historyModelTemplate'></ng-template>
      </div>
      <pre *ngIf='h.model && !historyModelTemplate'>{{h.model | json}}</pre>
    </div>
    <div *ngIf='!history?.length' class='alert alert-secondary'>No history found.</div>
    <div *ngIf='history?.length' class='alert alert-warning'><strong>Note:</strong> 10 most recent entries are shown.</div>
  </div>
</app-blade>
