import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { orderBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { Perspective, PerspectiveSlug } from './perspective';
import { IPerspectiveMetadata } from './perspective-metadata';
import { PinningService } from './pinning.service';

@Component({
  selector: 'app-compare-tabs',
  templateUrl: './compare-tabs.component.html',
  styleUrls: ['./compare-tabs.component.scss']
})
export class CompareTabsComponent implements OnInit, OnDestroy {

  public perspectiveId: string;
  public perspectiveMetadata: IPerspectiveMetadata[];
  public perspective?: Perspective;
  // noinspection JSMismatchedCollectionQueryUpdate
  private routeParamsSubscription: Subscription;
  private routeDataSubscription: Subscription;

  public constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly pinningService: PinningService
  ) {
    this.perspectiveId = route.snapshot.params.networkId || route.snapshot.params.regionId;
  }

  private _appId: string;

  public get appId() { return this._appId; }

  public get unpinnedId() { return this.pinningService.pins(this.perspective).includes(this.perspectiveId) ? undefined : this.perspectiveId; }

  public get allUnpinned(): IPerspectiveMetadata[] {
    return orderBy(this.perspectiveMetadata.filter(x => x.active && (x.enabled ?? true) && !this.pins.some(p => p === x.id)), x => x.displayName);
  }

  public get SpendOptimisationPerspectiveSlug() { return PerspectiveSlug; }

  public get pins(): string[] {
    return orderBy(this.pinningService.pins(this.perspective).filter(x => this.displayName(x) && this.shouldPinDisplay(x)), x => this.displayName(x));
  }

  public get isCompare() { return !/(?:networks|regions)\/[\da-f]{8}-/.test(this.router.routerState.snapshot.url); }

  public get childRouteSegments() {
    return this.isCompare ? [] : this.router.routerState.snapshot.url.replace(/.+\/(?:networks|regions)\/[\da-f\-]+\//i, '').split('/');
  }

  public shouldPinDisplay(id: string) {
    return this.perspectiveMetadata.some(x => x.id === id);
  }

  public ngOnInit(): void {
    this.routeParamsSubscription = this.route.params.subscribe(p => this._appId = p.appId);
    this.routeDataSubscription = this.route.data.subscribe(d => {
      this.perspectiveMetadata = d.perspectiveMetadata || d.networks || [];
      this.perspective = d.perspective;
    });
  }

  public ngOnDestroy(): void {
    this.routeParamsSubscription?.unsubscribe();
    this.routeDataSubscription?.unsubscribe();
  }

  public pin(id: string) { this.pinningService.pin(id, this.perspective); }

  public unpin(id: string) { this.pinningService.unpin(id, this.perspective); }

  public displayName(id: string) { return this.perspectiveMetadata.find(x => x.id === id)?.displayName || ''; }

}
