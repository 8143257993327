import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[focus]'
})
export class FocusDirective implements AfterViewInit {

  public constructor(private host: ElementRef) { }

  public ngAfterViewInit() { this.host.nativeElement.focus(); }

}
