import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from './auth.service';

/**
 * Tests if the user is authenticated with a valid account that is associated with a valid advertiser and removes content
 * from the attached element if they don't. Equivalent to *ngIf="auth.profile?.activeAdvertiserId".
 */
@Directive({
  selector: '[appHasValidAccount]'
})
export class HasValidAccountDirective implements OnInit {

  public constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly auth: AuthService
  ) { }

  public ngOnInit(): void {
    this.auth.profile$.subscribe(() => {
      this.updateView();
    });
  }

  private updateView() {
    this.viewContainer.clear();
    if (this.auth.profile.activeAdvertiserId) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
