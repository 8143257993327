import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors } from '@angular/forms';
import { first, flatMap, intersection, orderBy, uniq, without } from 'lodash-es';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { FormHelperService } from '../../shared/services/form-helper.service';
import { ComparePageSettingsService } from './compare-page-settings.service';

@Component({
  selector: 'app-compare-page-settings',
  templateUrl: './compare-page-settings.component.html',
  styleUrls: ['./compare-page-settings.component.scss']
})
export class ComparePageSettingsComponent implements OnDestroy {

  @ViewChild('pageSettings', { static: false }) public template: TemplateRef<any>;

  public modal: BsModalRef;

  public form = this.fb.group({
    columnMetrics: new FormControl<string[] | null>([], [(control: AbstractControl): ValidationErrors | null =>
      control.value?.length < 1
        ? { custom: 'You must select at one metric column' }
        : control.value?.length > 10
          ? { custom: 'The maximum number of metric columns permitted is 10' }
          : null
    ]),
    benefitMetric: new FormControl<string | null>(null, [(control: AbstractControl): ValidationErrors | null =>
      !control.value
        ? { custom: 'You must select at a metric for comparison with share of spend' }
        : null
    ])
  });

  public saving = false;
  public availableColumnMetrics: IAttributionMetadataMetricDto[];
  public availableBenefitMetrics: IAttributionMetadataMetricDto[];

  public constructor(
    private readonly modalService: BsModalService,
    private readonly pageSettingsService: ComparePageSettingsService,
    private readonly formHelper: FormHelperService,
    private readonly fb: FormBuilder
  ) {
    this.initializeForm();
  }

  public ngOnDestroy() {
    try { this.modal?.hide(); } catch { /* Doesn't matter if this fails */ }
  }

  public toggleColumnMetric(name: string) {
    if (this.form.value.columnMetrics.includes(name)) {
      this.form.controls.columnMetrics.setValue(without(this.form.value.columnMetrics, name));
    } else {
      this.form.controls.columnMetrics.setValue(this.form.value.columnMetrics.concat(name));
    }
  }

  public setBenefitMetric(name: string) {
    this.form.controls.benefitMetric.setValue(name);
  }

  public moveMetricToTop(name: string, event: MouseEvent) {
    event.stopPropagation();
    const oldIndex = this.availableColumnMetrics.findIndex(x => x.name === name);
    const spendIndex = this.availableColumnMetrics.findIndex(x => x.name === 'spend');
    if (spendIndex === 0) {
      this.availableColumnMetrics = flatMap([
        this.availableColumnMetrics[0],
        this.availableColumnMetrics[oldIndex],
        this.availableColumnMetrics.slice(1, oldIndex),
        this.availableColumnMetrics.slice(oldIndex + 1)]);
    } else {
      this.availableColumnMetrics = flatMap([
        this.availableColumnMetrics[oldIndex],
        this.availableColumnMetrics.slice(0, oldIndex),
        this.availableColumnMetrics.slice(oldIndex + 1)]);
    }
  }

  public async show() {
    const availableColumnMetrics = await this.pageSettingsService.getAvailableColumnMetrics();
    const availableBenefitMetrics = await this.pageSettingsService.getAvailableBenefitMetrics();

    const settings = await this.pageSettingsService.get();
    const validColumnMetricNames = availableColumnMetrics.map(x => x.name);

    this.form.controls.columnMetrics.setValue(intersection(settings?.columnMetrics, validColumnMetricNames) || []);
    this.form.controls.columnMetrics.markAsDirty();

    this.form.controls.benefitMetric.setValue(availableBenefitMetrics.some(x => x.name === settings?.benefitMetric) ? settings?.benefitMetric : first(availableBenefitMetrics)?.name);
    this.form.controls.columnMetrics.markAsDirty();

    this.availableColumnMetrics = orderBy(availableColumnMetrics, x => (x.name === 'spend' || this.form.controls.columnMetrics.value.includes(x.name)) ? this.form.controls.columnMetrics.value.findIndex(m => x.name === m) : 99999);
    this.availableBenefitMetrics = orderBy(availableBenefitMetrics, x => this.form.controls.columnMetrics.value.includes(x.name) ? this.form.controls.columnMetrics.value.findIndex(m => x.name === m) : 99999);

    this.modal = this.modalService.show(this.template, {
      keyboard: true,
      class: 'modal-lg modal-dialog-centered modal-page-settings compare'
    });
  }

  public async submit() {
    if (!this.form.valid || this.saving) {
      this.formHelper.markAllAsDirty(this.form);
      return;
    }
    this.saving = true;
    try {
      this.pageSettingsService.update({
        columnMetrics: uniq(orderBy(this.form.value.columnMetrics, x => this.availableColumnMetrics.findIndex(a => a.name === x))),
        benefitMetric: this.form.value.benefitMetric
      });
    } finally {
      this.saving = false;
      this.modal.hide();
    }
  }

  public async reset() {
    this.saving = true;
    try {
      this.pageSettingsService.reset();
    } finally {
      this.saving = false;
      this.modal.hide();
    }
  }

  private initializeForm() {
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.saving = false;
  }

}
