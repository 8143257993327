<ng-select #ngSelectControl (focus)="focus()" (ngModelChange)="onChange($event)" (touchstart)="onTouched($event)" [(ngModel)]="value" [appendTo]="appendTo" [bindLabel]="bindLabel"
        [bindValue]="bindValue" [class]="class" [clearSearchOnAdd]="clearSearchOnAdd" [clearable]="clearable" [closeOnSelect]="false" [compareWith]="compareWith" [disabled]="disabled"
        [groupBy]="groupBy"
        [items]="items | async" [loading]="loading" [multiple]="true" [searchable]="searchable" [selectableGroup]="true" [typeahead]="typeahead">
  <ng-template ng-header-tmp>
    <button (click)="selectAll()" *ngIf="!value?.length" class="select-all">
      <!--suppress HtmlFormInputWithoutLabel -->
      <input checked="checked" type="checkbox" /> Select all
    </button>
    <button (click)="ngSelectControl.clearModel()" *ngIf="value?.length" class="select-all">
      <!--suppress HtmlFormInputWithoutLabel -->
      <input type="checkbox" /> Unselect all
    </button>
  </ng-template>
  <ng-template let-index="index" let-item="item" let-item$="item$" ng-optgroup-tmp>
    <!--suppress HtmlFormInputWithoutLabel -->
    <input [ngModel]="item$.selected" id="item-group-{{index}}" type="checkbox" /> {{item[groupBy]}}
  </ng-template>
  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
    <!--suppress HtmlFormInputWithoutLabel -->
    <input [ngModel]="item$.selected" id="item-{{index}}" type="checkbox" /> {{item[bindLabel] || item}}
  </ng-template>
  <ng-template let-clear="clear" let-items="items" ng-multi-label-tmp>
    <div *ngFor="let item of items | slice:0:maxTokens" class="ng-value">
      <span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left">×</span>
      <span class="ng-value-label">{{item[bindToken] || item[bindLabel] || item[groupBy]}}</span>
    </div>
    <div *ngIf="value?.length > maxTokens" class="ng-value">
      <span class="ng-value-label">{{value?.length - maxTokens}} more&hellip;</span>
    </div>
  </ng-template>
</ng-select>
