<app-page-header title="Analysis">
  <div class="right-content">
    <app-app-selector></app-app-selector>
  </div>
</app-page-header>
<app-analysis-tabs></app-analysis-tabs>
<app-compare-tabs *ngIf="showCompareTabs"></app-compare-tabs>
<app-network-drilldown-tabs *ngIf="showNetworkDrilldownTabs"></app-network-drilldown-tabs>
<app-analysis-settings [showNetwork]="showNetworkFilter" [showRegion]="showRegionFilter"></app-analysis-settings>

<section class="page-area">
  <div class="padded-content top-bottom-padding">
    <app-content-header [helpArticleId]="helpArticleId" [iconUrls]="iconUrls" [title]="titleBreadcrumbs">
      <app-recent-trends-page-settings class="right-content"></app-recent-trends-page-settings>
    </app-content-header>
    <app-drilldown-tabs></app-drilldown-tabs>

    <div *ngIf="analysis" class="key-stat tiles">
      <div *ngFor="let tile of toplineTiles" class="banner tile">
        <header>
          <h3>{{tile.config.displayName}}</h3>
        </header>
        <div class="stat-with-label">
          <strong class="key-stat-value">
            <app-metric-current-value *ngIf="tile.analysis.current" [config]="tile.config" [value]="tile.analysis.current"></app-metric-current-value>
            <span *ngIf="!tile.analysis.current">-</span>
          </strong>
          <span class="key-stat-label">current</span>
        </div>
        <div class="stat-with-label">
          <div class="key-stat-value">
            <highcharts-chart *ngIf="tile.miniTrendChart" [Highcharts]="Highcharts" [options]="tile.miniTrendChart" oneToOne="true" runOutsideAngular="true"></highcharts-chart>
            <span *ngIf="tile.config.neutral" [innerHTML]="tile.analysis.percentageChange | neutralPercentageChange:'1.0-0'"></span>
            <span *ngIf="!tile.config.neutral" [innerHTML]="tile.analysis.percentageChange | percentageChange:'1.0-0':tile.config.negativeIsGood"></span>
          </div>
          <span class="key-stat-label" [innerHTML]="cadenceTrendLabel(tile.miniTrendChart) + ' trend'"></span>
        </div>
      </div>
    </div>

    <div *ngIf="analysis" class="key-stat chart tiles">
      <div *ngFor="let tile of headlineTiles" class="tile">
        <header>
          <h3>{{tile.config.displayName}}</h3>
        </header>
        <div class="stats">
          <div class="stat-with-label">
            <strong class="key-stat-value">
              <app-metric-current-value *ngIf="tile.analysis.current" [config]="tile.config" [value]="tile.analysis.current"></app-metric-current-value>
              <span *ngIf="!tile.analysis.current">-</span>
            </strong>
            <span class="key-stat-label">current</span>
          </div>
          <div class="stat-with-label">
            <div class="key-stat-value">
              <highcharts-chart *ngIf="tile.miniTrendChart" [Highcharts]="Highcharts" [options]="tile.miniTrendChart" oneToOne="true" runOutsideAngular="true"></highcharts-chart>
              <span *ngIf="tile.config.neutral" [innerHTML]="tile.analysis.percentageChange | neutralPercentageChange:'1.0-0'"></span>
              <span *ngIf="!tile.config.neutral" [innerHTML]="tile.analysis.percentageChange | percentageChange:'1.0-0':tile.config.negativeIsGood"></span>
            </div>
            <span class="key-stat-label" [innerHTML]="cadenceTrendLabel(tile.miniTrendChart) + ' trend'"></span>
          </div>
        </div>
        <div *ngIf="tile.gaugeChart" class="gauge">
          <highcharts-chart [Highcharts]="Highcharts" [options]="tile.gaugeChart" oneToOne="true" runOutsideAngular="true"></highcharts-chart>
          <div class="target">
            <div class="key-bullet"></div>
            <span class="label">Target:</span>
            <strong>{{tile.config.displayPrefix}}{{tile.analysis.targetMin | metricValue:tile.config:{ decimals: 0, prefix: '', suffix: '' } }}</strong>
            <span class="to">to</span>
            <strong>{{tile.analysis.targetMax | metricValue:tile.config:{ decimals: 0, prefix: '', suffix: '' } }}{{tile.config.displaySuffix}}</strong>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="timeseriesTiles?.length" class="chart tiles two-columns">
      <ng-container *ngFor="let row of timeseriesTileRows">
        <div *ngFor="let tile of row" class="tile">
          <header><h3>{{tile.config.displayName}}</h3></header>
          <highcharts-chart [Highcharts]="Highcharts" [options]="tile.timeseriesChart" class="auto-width" oneToOne="true" runOutsideAngular="true"></highcharts-chart>
        </div>
      </ng-container>
    </div>

    <div *ngIf="costCurvesChart" class="chart tiles">
      <div *ngIf="costCurvesMetrics" class="tile">
        <header>
          <h3>Cost Curves</h3>
          <div class="control-with-label">
            <span class="label">Last touch data:</span>
            <ui-switch (ngModelChangeDebounced)="configureCostCurvesChart()" [(ngModel)]="showLastTouchData" [ngModelChangeDebounceTime]="50"></ui-switch>
          </div>
          <div class="control-with-label">
            <span class="label">Historic max:</span>
            <ui-switch (ngModelChangeDebounced)="configureCostCurvesChart()" [(ngModel)]="showHistoricMax" [ngModelChangeDebounceTime]="50"></ui-switch>
          </div>
          <div class="control-with-label">
            <span class="label">Optimising towards:</span>
            <ui-switch (ngModelChangeDebounced)="configureCostCurvesChart()" [(ngModel)]="showOptimisingTowards" [ngModelChangeDebounceTime]="50"></ui-switch>
          </div>
          <div class="control-with-label">
            <span class="label">Goal:</span>
            <ng-select (ngModelChangeDebounced)="configureCostCurvesChart()" [(ngModel)]="costCurvesMetricName" [clearable]="false" [items]="costCurvesMetricNames" [ngModelChangeDebounceTime]="50"
                    [searchable]="false"></ng-select>
          </div>
        </header>
        <highcharts-chart [Highcharts]="Highcharts" [options]="costCurvesChart" class="auto-width" oneToOne="true" runOutsideAngular="true"></highcharts-chart>
      </div>
    </div>
  </div>
</section>
