import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors } from '@angular/forms';
import { flatMap, intersection, orderBy, uniq, without } from 'lodash-es';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { FormHelperService } from '../../shared/services/form-helper.service';
import { RawDataPageSettingsService } from './raw-data-page-settings.service';

@Component({
  selector: 'app-raw-data-page-settings',
  templateUrl: './raw-data-page-settings.component.html',
  styleUrls: ['./raw-data-page-settings.component.scss']
})
export class RawDataPageSettingsComponent implements OnDestroy {

  @ViewChild('pageSettings', { static: false }) public template: TemplateRef<any>;

  public modal: BsModalRef;
  public form = this.fb.group({
    columnMetrics: new FormControl<string[] | null>([], [(control: AbstractControl): ValidationErrors | null =>
      control.value?.length < 1 ? { custom: 'You must select at least one metric' } : null
    ])
  });
  public saving = false;

  public availableColumnMetrics: IAttributionMetadataMetricDto[];

  public constructor(
    private readonly modalService: BsModalService,
    private readonly pageSettingsService: RawDataPageSettingsService,
    private readonly formHelper: FormHelperService,
    private readonly fb: FormBuilder
  ) {
    this.initializeForm();
  }

  public ngOnDestroy() {
    try { this.modal?.hide(); } catch { /* Doesn't matter if this fails */ }
  }

  public toggleColumnMetric(name: string) {
    if (this.form.value.columnMetrics.includes(name)) {
      this.form.controls.columnMetrics.setValue(without(this.form.value.columnMetrics, name));
    } else {
      this.form.controls.columnMetrics.setValue(this.form.value.columnMetrics.concat(name));
    }
  }

  public moveColumnMetricToTop(name: string, event: MouseEvent) {
    event.stopPropagation();
    const oldIndex = this.availableColumnMetrics.findIndex(x => x.name === name);
    this.availableColumnMetrics = flatMap([
      this.availableColumnMetrics[oldIndex],
      this.availableColumnMetrics.slice(0, oldIndex),
      this.availableColumnMetrics.slice(oldIndex + 1)]);
  }

  public async show() {
    const availableColumnMetrics = await this.pageSettingsService.getAvailableColumnMetrics();
    const settings = await this.pageSettingsService.get();
    const validMetricNames = availableColumnMetrics.map(x => x.name);
    this.form.controls.columnMetrics.setValue(intersection(settings?.columnMetrics, validMetricNames) || []);
    this.formHelper.markAllAsDirty(this.form);
    this.availableColumnMetrics = orderBy(availableColumnMetrics,
      x => this.form.controls.columnMetrics.value.includes(x.name) ? this.form.controls.columnMetrics.value.findIndex(m => x.name === m) : 99999);
    this.modal = this.modalService.show(this.template, {
      keyboard: true,
      class: 'modal-md modal-dialog-centered modal-page-settings raw-data'
    });
  }

  public async submit() {
    if (!this.form.valid || this.saving) {
      this.formHelper.markAllAsDirty(this.form);
      return;
    }
    this.saving = true;
    try {
      this.pageSettingsService.update({
        columnMetrics: uniq(orderBy(this.form.value.columnMetrics, x => this.availableColumnMetrics.findIndex(a => a.name === x)))
      });
    } finally {
      this.saving = false;
      this.modal.hide();
    }
  }

  public async reset() {
    this.saving = true;
    try {
      this.pageSettingsService.reset();
    } finally {
      this.saving = false;
      this.modal.hide();
    }
  }

  private initializeForm() {
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.saving = false;
  }

}
