<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
</div>
<div class="modal-body">
  <div class="content">
    <i *ngIf="iconClass" class="{{iconClass}}"></i>
    <p [innerHtml]="message"></p>
  </div>
</div>
<div class="modal-footer">
  <button *ngFor="let button of buttons" type="button" class="btn {{button.buttonClass || 'btn-secondary'}}" (click)="close(button)">{{button.label}}</button>
</div>
