import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors } from '@angular/forms';
import { flatMap, intersection, orderBy, uniq, without } from 'lodash-es';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { FormHelperService } from '../../shared/services/form-helper.service';
import { RecentTrendsPageSettingsService } from './recent-trends-page-settings.service';

@Component({
  selector: 'app-recent-trends-page-settings',
  templateUrl: './recent-trends-page-settings.component.html',
  styleUrls: ['./recent-trends-page-settings.component.scss']
})
export class RecentTrendsPageSettingsComponent implements OnDestroy {

  @ViewChild('pageSettings', { static: false }) public template: TemplateRef<any>;

  public modal: BsModalRef;
  public form = this.fb.group({
    toplineMetrics: new FormControl<string[] | null>([], [(control: AbstractControl): ValidationErrors | null =>
      control.value?.length !== 2 ? { custom: 'You must select at exactly two top-line metrics' } : null
    ]),
    headlineMetrics: new FormControl<string[] | null>([], [(control: AbstractControl): ValidationErrors | null =>
      control.value?.length !== 3 ? { custom: 'You must select at exactly three headline metrics' } : null
    ]),
    timeseriesChartMetrics: new FormControl<string[] | null>([], [(control: AbstractControl): ValidationErrors | null =>
      control.value?.length < 2 ? { custom: 'You must select at least two chart metrics' } : null
    ])
  });
  public saving = false;

  public availableToplineMetrics: IAttributionMetadataMetricDto[];

  public availableHeadlineMetrics: IAttributionMetadataMetricDto[];

  public availableTimeseriesChartMetrics: IAttributionMetadataMetricDto[];

  public constructor(
    private readonly modalService: BsModalService,
    private readonly pageSettingsService: RecentTrendsPageSettingsService,
    private readonly formHelper: FormHelperService,
    private readonly fb: FormBuilder
  ) {
    this.initializeForm();
  }

  public ngOnDestroy() {
    try { this.modal?.hide(); } catch { /* Doesn't matter if this fails */ }
  }

  public toggleToplineMetric(name: string) {
    if (this.form.value.toplineMetrics.includes(name)) {
      this.form.controls.toplineMetrics.setValue(without(this.form.value.toplineMetrics, name));
    } else {
      this.form.controls.toplineMetrics.setValue(this.form.value.toplineMetrics.concat(name));
    }
  }

  public toggleHeadlineMetric(name: string) {
    if (this.form.value.headlineMetrics.includes(name)) {
      this.form.controls.headlineMetrics.setValue(without(this.form.value.headlineMetrics, name));
    } else {
      this.form.controls.headlineMetrics.setValue(this.form.value.headlineMetrics.concat(name));
    }
  }

  public toggleChartMetric(name: string) {
    if (this.form.value.timeseriesChartMetrics.includes(name)) {
      this.form.controls.timeseriesChartMetrics.setValue(without(this.form.value.timeseriesChartMetrics, name));
    } else {
      this.form.controls.timeseriesChartMetrics.setValue(this.form.value.timeseriesChartMetrics.concat(name));
    }
  }

  public moveToplineMetricToTop(name: string, event: MouseEvent) {
    event.stopPropagation();
    const oldIndex = this.availableToplineMetrics.findIndex(x => x.name === name);
    this.availableToplineMetrics = flatMap([
      this.availableToplineMetrics[oldIndex],
      this.availableToplineMetrics.slice(0, oldIndex),
      this.availableToplineMetrics.slice(oldIndex + 1)]);
  }

  public moveHeadlineMetricToTop(name: string, event: MouseEvent) {
    event.stopPropagation();
    const oldIndex = this.availableHeadlineMetrics.findIndex(x => x.name === name);
    this.availableHeadlineMetrics = flatMap([
      this.availableHeadlineMetrics[oldIndex],
      this.availableHeadlineMetrics.slice(0, oldIndex),
      this.availableHeadlineMetrics.slice(oldIndex + 1)]);
  }

  public moveChartMetricToTop(name: string, event: MouseEvent) {
    event.stopPropagation();
    const oldIndex = this.availableTimeseriesChartMetrics.findIndex(x => x.name === name);
    this.availableTimeseriesChartMetrics = flatMap([
      this.availableTimeseriesChartMetrics[oldIndex],
      this.availableTimeseriesChartMetrics.slice(0, oldIndex),
      this.availableTimeseriesChartMetrics.slice(oldIndex + 1)]);
  }

  public async show() {
    const availableToplineMetrics = await this.pageSettingsService.getAvailableToplineMetrics();
    const availableHeadlineMetrics = await this.pageSettingsService.getAvailableHeadlineMetrics();
    const availableChartMetrics = await this.pageSettingsService.getAvailableTimeseriesChartMetrics();
    const settings = await this.pageSettingsService.get();

    const validToplineMetricNames = availableToplineMetrics.map(x => x.name);
    const validHeadlineMetricNames = availableToplineMetrics.map(x => x.name);
    const validChartMetricNames = availableChartMetrics.map(x => x.name);

    this.form.controls.toplineMetrics.setValue(intersection(settings?.toplineMetrics, validToplineMetricNames) || []);
    this.form.controls.headlineMetrics.setValue(intersection(settings?.headlineMetrics, validHeadlineMetricNames) || []);
    this.form.controls.timeseriesChartMetrics.setValue(intersection(settings?.timeseriesChartMetrics, validChartMetricNames) || []);

    this.formHelper.markAllAsDirty(this.form);

    this.availableToplineMetrics = orderBy(availableToplineMetrics,
      x => this.form.controls.toplineMetrics.value.includes(x.name) ? this.form.controls.toplineMetrics.value.findIndex(m => x.name === m) : 99999);
    this.availableHeadlineMetrics = orderBy(availableHeadlineMetrics,
      x => this.form.controls.headlineMetrics.value.includes(x.name) ? this.form.controls.headlineMetrics.value.findIndex(m => x.name === m) : 99999);
    this.availableTimeseriesChartMetrics = orderBy(availableChartMetrics,
      x => this.form.controls.timeseriesChartMetrics.value.includes(x.name) ? this.form.controls.timeseriesChartMetrics.value.findIndex(m => x.name === m) : 99999);

    this.modal = this.modalService.show(this.template, {
      keyboard: true,
      class: 'modal-xl modal-dialog-centered modal-page-settings recent-trends'
    });
  }

  public async submit() {
    if (!this.form.valid || this.saving) {
      this.formHelper.markAllAsDirty(this.form);
      return;
    }
    this.saving = true;
    try {
      this.pageSettingsService.update({
        toplineMetrics: uniq(orderBy(this.form.value.toplineMetrics, x => this.availableToplineMetrics.findIndex(a => a.name === x))),
        headlineMetrics: uniq(orderBy(this.form.value.headlineMetrics, x => this.availableHeadlineMetrics.findIndex(a => a.name === x))),
        timeseriesChartMetrics: uniq(orderBy(this.form.value.timeseriesChartMetrics, x => this.availableTimeseriesChartMetrics.findIndex(a => a.name === x)))
      });
    } finally {
      this.saving = false;
      this.modal.hide();
    }
  }

  public async reset() {
    this.saving = true;
    try {
      this.pageSettingsService.reset();
    } finally {
      this.saving = false;
      this.modal.hide();
    }
  }

  private initializeForm() {
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.saving = false;
  }

}
