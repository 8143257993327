<div *ngIf="region" class="filtered-by">
  <span class="label">Filtered on</span>
  <img alt="{{region.displayName}}" src="{{region.iconUrl || '/assets/images/default-region-icon.svg'}}" />
  <span class="name">{{region.displayName}}</span>
</div>
<div *ngIf="network" class="filtered-by">
  <span class="label">Filtered on</span>
  <img alt="{{network.displayName}}" src="{{network.iconUrlSmall || '/assets/images/default-network-icon.svg'}}" />
  <span class="name">{{network.displayName}}</span>
</div>
