<a (click)="show()"><i class="fa-solid fa-gear page-settings"></i></a>

<ng-template #pageSettings>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Page Settings</h4>
    <button (click)="modal.hide()" aria-label="Close" class="close float-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body no-padding">
    <div class="columns">
      <div class="top-line metrics picklist single-column">
        <h4>Top-line Metrics</h4>
        <ul>
          <li (click)="toggleToplineMetric(metric.name)" *ngFor="let metric of availableToplineMetrics" [class.selected]="form.value.toplineMetrics.includes(metric.name)">
            <i [class.fa-check-square]="form.value.toplineMetrics.includes(metric.name)" [class.fa-square]="!form.value.toplineMetrics.includes(metric.name)" class="fas"></i>
            <span class="name">{{metric.displayName}}</span>
            <span class="gap"></span>
            <a (click)="moveToplineMetricToTop(metric.name, $event)" *ngIf="form.value.toplineMetrics.includes(metric.name)"><i class="fa-regular fa-circle-up"></i></a>
          </li>
        </ul>
        <app-form-validation-messages [model]="form.controls.toplineMetrics"></app-form-validation-messages>
      </div>
      <div class="headline metrics picklist single-column">
        <h4>Headline Metrics</h4>
        <ul>
          <li (click)="toggleHeadlineMetric(metric.name)" *ngFor="let metric of availableHeadlineMetrics" [class.selected]="form.value.headlineMetrics.includes(metric.name)">
            <i [class.fa-check-square]="form.value.headlineMetrics.includes(metric.name)" [class.fa-square]="!form.value.headlineMetrics.includes(metric.name)" class="fas"></i>
            <span class="name">{{metric.displayName}}</span>
            <span class="gap"></span>
            <a (click)="moveHeadlineMetricToTop(metric.name, $event)" *ngIf="form.value.headlineMetrics.includes(metric.name)"><i class="fa-regular fa-circle-up"></i></a>
          </li>
        </ul>
        <app-form-validation-messages [model]="form.controls.headlineMetrics"></app-form-validation-messages>
      </div>
      <div class="chart metrics picklist single-column">
        <h4>Chart Metrics</h4>
        <ul>
          <li (click)="toggleChartMetric(metric.name)" *ngFor="let metric of availableTimeseriesChartMetrics" [class.selected]="form.value.timeseriesChartMetrics.includes(metric.name)">
            <i [class.fa-check-square]="form.value.timeseriesChartMetrics.includes(metric.name)" [class.fa-square]="!form.value.timeseriesChartMetrics.includes(metric.name)" class="fas"></i>
            <span class="name">{{metric.displayName}}</span>
            <span class="gap"></span>
            <a (click)="moveChartMetricToTop(metric.name, $event)" *ngIf="form.value.timeseriesChartMetrics.includes(metric.name)"><i class="fa-regular fa-circle-up"></i></a>
          </li>
        </ul>
        <app-form-validation-messages [model]="form.controls.timeseriesChartMetrics"></app-form-validation-messages>
        <div class="organic-warning">
          <i class="fa-solid fa-circle-exclamation"></i>
          <span>Organic metrics will not be shown in contexts where you are filtered to a single network</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-footer-content">
      <div class="left">
        <button (click)="submit()" [disabled]="!form.valid || saving" class="btn btn-primary">Save</button>
        <button (click)="modal.hide()" class="btn btn-secondary">Cancel</button>
      </div>
      <div class="right">
        <button (click)="reset()" class="btn btn-secondary">Reset to Default</button>
      </div>
    </div>
  </div>
</ng-template>
