import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isNil, last } from 'lodash-es';
import { Subscription } from 'rxjs';
import { IAppDto } from '../../api-model/app-dto';
import { AppsDataService } from '../../apps/apps-data.service';
import { OsNamePipe } from '../../shared/pipes/os-name.pipe';

@Component({
  selector: 'app-app-selector',
  templateUrl: './app-selector.component.html',
  styleUrls: ['./app-selector.component.scss']
})
export class AppSelectorComponent implements OnInit, OnDestroy {

  @ViewChild(NgSelectComponent) public app: NgSelectComponent;
  // noinspection JSMismatchedCollectionQueryUpdate
  public apps: IAppDto[] = [];
  public appId: string;
  private appsSubscription: Subscription;
  private routeParamsSubscription: Subscription;

  public constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly appsDataService: AppsDataService,
    private readonly osName: OsNamePipe
  ) { }

  public selectApp(app: IAppDto) {
    if (!app?.id) { return; }

    const segments = this.router.url.split('/');
    const appIdPosition = segments.indexOf('apps');
    if (appIdPosition < 0) { return; }
    segments.splice(appIdPosition + 1, 1, app.id);

    // Walk back up the URL tree to a the place where the current route says we must land after an app change
    const removeSegmentsAfter = this.route.snapshot.data.redirectToUrlSegmentOnAppChange?.toLowerCase();
    while (removeSegmentsAfter && segments.length && last(segments).toLowerCase() !== removeSegmentsAfter) { segments.pop(); }

    // If we have no URL segments (because the route configuration is invalid) or the route configuration did not allow us to perform an in-place URL swap, redirect to the AIM landing page
    if (!segments.length || (!removeSegmentsAfter && !this.route.snapshot.data.redirectToCurrentUrlOnAppChange)) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(segments);
    }

    this.app.handleClearClick();
  }

  public iconUrl(app: IAppDto) {
    if (app?.iconUrlSmall) { return app.iconUrlSmall; }
    const operatingSystem = app?.operatingSystem;
    return `/assets/images/default-app-icon${!isNil(operatingSystem) ? '-' : ''}${this.osName.transform(operatingSystem).toLowerCase()}.svg`;
  }

  public ngOnInit(): void {
    this.routeParamsSubscription = this.route.params.subscribe(async p => {
      this.appId = p.appId;
      this.apps = await this.appsDataService.get();
    });
    this.appsSubscription = this.appsDataService.apps$.subscribe(x => this.apps = x || []);
  }

  public ngOnDestroy(): void {
    this.routeParamsSubscription?.unsubscribe();
    this.appsSubscription?.unsubscribe();
  }

  public asIAppDto(value: any) { return value as IAppDto; }

}
