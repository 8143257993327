import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-analysis-tabs',
  templateUrl: './analysis-tabs.component.html',
  styleUrls: ['./analysis-tabs.component.scss']
})
export class AnalysisTabsComponent implements OnInit, OnDestroy {

  private routeParamsSubscription: Subscription;

  public constructor(private readonly route: ActivatedRoute, public readonly router: Router) { }

  private _appId: string;

  public get appId() { return this._appId; }

  public ngOnInit(): void {
    this.routeParamsSubscription = this.route.params.subscribe(p => this._appId = p.appId);
  }

  public ngOnDestroy(): void {
    this.routeParamsSubscription?.unsubscribe();
  }

  public routerLinkSegments(perspective: string) {
    return [
      '/analysis',
      'apps',
      this.appId,
      perspective
    ].filter(x => x);
  }

  public isActive(perspective: string) {
    return this.router.routerState.snapshot.url.includes(`/${perspective}/`) || this.router.routerState.snapshot.url.endsWith(`/${perspective}`);
  }

  public isAppTabActive() {
    return !/apps\/[a-f0-9\-]+\/(?:networks|regions)/i.test(this.router.routerState.snapshot.url);
  }

}
