<app-page-header title="Analysis">
  <div class="right-content">
    <app-app-selector></app-app-selector>
  </div>
</app-page-header>
<app-analysis-tabs></app-analysis-tabs>
<app-compare-tabs *ngIf="showCompareTabs"></app-compare-tabs>
<app-network-drilldown-tabs *ngIf="showNetworkDrilldownTabs"></app-network-drilldown-tabs>
<app-analysis-settings [showNetwork]="showNetworkFilter" [showRegion]="showRegionFilter" showCadence="true"></app-analysis-settings>

<section class="page-area">
  <div class="padded-content top-bottom-padding">
    <app-content-header [iconUrls]="iconUrls" [title]="titleBreadcrumbs" helpArticleId="4755907771673">
      <div class="right-content btn-download">
          <div *ngIf="!this.network" class="btn-group" dropdown>
            <a dropdownToggle><i class="fa-solid fa-download page-settings"></i></a>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
              <li class="disabled" role="menuitem"><a class="dropdown-item disabled">Include :</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem" (click)="exportAllNetworks(0)"><a class="dropdown-item">Networks</a></li>
              <li role="menuitem" (click)="exportAllNetworks(1)"><a class="dropdown-item">Publishers</a></li>
              <li role="menuitem" (click)="exportAllNetworks(2)"><a class="dropdown-item">Campaigns</a></li>
            </ul>
          </div>
          <div *ngIf="this.network">
            <a (click)="exportCurrentNetwork()"><i class="fa-solid fa-file-csv page-settings"></i></a>
          </div>
      </div>
      <app-compare-page-settings class="right-content"></app-compare-page-settings>
    </app-content-header>

    <app-compare-data-table>
      <app-data-table>
        <table class="dynamic-columns">
          <thead>
            <tr class="category-row">
              <th>&nbsp;</th>
              <th *ngIf="columnMetrics.length" [colSpan]="columnMetrics.length">Metrics</th>
              <th>Performance</th>
              <th *ngIf="hasRecommendation" [colSpan]="2">Recommendation</th>
            </tr>
            <tr>
              <th class="left-line">
                <a (click)="toggleSort('name', SortOrder.Ascending)" *ngIf="canSort" class="{{sortClass('name')}}">{{PerspectiveSingularDesc[perspective]}}</a>
                <strong *ngIf="!canSort">{{PerspectiveSingularDesc[perspective]}}</strong>
              </th>
              <th *ngFor="let metric of columnMetrics" class="left-line">
                <a (click)="toggleSort(metric.name, SortOrder.Descending)" *ngIf="canSort" [tooltip]="metric.description" class="{{sortClass(metric.name)}}" container="body"
                        delay="500">{{metric.shortDisplayName || metric.displayName}}</a>
                <strong *ngIf="!canSort" [tooltip]="metric.description" container="body" delay="500">{{metric.shortDisplayName || metric.displayName}}</strong>
              </th>
              <th class="left-line"><a (click)="toggleSort('share-of-benefit', SortOrder.Descending)" class="{{sortClass('share-of-benefit')}}">
                Spend vs {{benefitMetric?.shortDisplayName || benefitMetric?.displayName || 'Benefit'}}
              </a></th>
              <th *ngIf="hasRecommendation" class="left-line">Next Period</th>
              <th *ngIf="hasRecommendation" class="left-line"><a (click)="toggleSort('recommended-spend', SortOrder.Descending)" class="{{sortClass('recommended-spend')}}">Action</a></th>
            </tr>
            <tr *ngIf="showAsSources" class="grand-total-row">
              <th class="right-line total-label">
                <div class="value">{{ grandTotalRow?.description?.displayName }}</div>
                <div class="caption">(Blended KPIs)</div>
              </th>
              <th *ngFor="let metric of grandTotalRow?.analysis?.metrics" [class.center]="!isMetricConfigSpend(metric)" [class.two-values]="isMetricConfigSpend(metric)" class="left-line total-value">
                <div *ngIf="isMetricConfigSpend(metric)">
                  <span *ngIf="!metric.current" class="no-ad-spend-label">No ad spend <br/> available</span>
                  <app-metric-current-value *ngIf="isMetricCurrentOrHasTrend(metric)" [config]="metric.config" [value]="metric.current" class="value"></app-metric-current-value>
                </div>
                <div *ngIf="!isMetricConfigSpend(metric)" class="value-with-change mini-trend-line">
                  <app-metric-current-value *ngIf="isMetricCurrentOrHasTrend(metric)" [config]="metric.config" [value]="metric.current" class="value"></app-metric-current-value>
                  <span *ngIf="!metric.current" class="no-ad-spend-label">N/A</span>
                  <span *ngIf="grandTotalRow.metricMiniTrendCharts[metric.config.name]"
                  [innerHTML]="metric.percentageChange | percentageChange:'1.0-0':metric.config.negativeIsGood:metric.config.neutral"
                  class="change"></span>
                </div>
                <highcharts-chart *ngIf="grandTotalRow.metricMiniTrendCharts[metric.config.name]" [Highcharts]="Highcharts"
                  [options]="grandTotalRow.metricMiniTrendCharts[metric.config.name]" oneToOne="true" runOutsideAngular="true"
                  [class.total-row-chart-m1]="isMetricConfigSpend(metric)"
                  [class.total-row-chart-m2]="!isMetricConfigSpend(metric)">
                </highcharts-chart>
              </th>
              <th class="left-line total-value">
              </th>
              <th *ngIf="hasRecommendation" class="left-line total-value"></th>
              <th *ngIf="hasRecommendation" class="center left-line total-value"></th>
            </tr>
            <tr *ngIf="showAsSources">
              <th colspan="100%" class="top-bottom-padding" class="table-sub-title">
                <label class="label">Paid Sources</label>
              </th>
            </tr>
            <tr class="legend">
              <th class="left-line"></th>
              <th *ngFor="let metric of columnMetrics" class="left-line"></th>
              <th class="left-line">
                <ul>
                  <li><span [style.background-color]="spendColour" class="bullet"></span><span class="label">Share of Spend</span></li>
                  <li><span [style.background-color]="benefitColour" class="bullet"></span><span
                          class="label">Share of {{benefitMetric?.shortDisplayName || benefitMetric?.displayName || 'Benefit'}}</span></li>
                </ul>
              </th>
              <th *ngIf="hasRecommendation" class="left-line" colspan="2">
                <ul class="label next-period-label">
                  <li><span>{{getRecommendationNextPeriod}}</span></li>
                </ul>
              </th>
            </tr>
          </thead>
          <!--############### Paid Sources ###################-->
          <tbody class="paid-sources">
            <tr class="network-row" *ngFor="let row of paidSourceRows">
              <!-- NAME -->
              <!-- temporary check to prevent click event if metric does not have any spending -->
              <td *ngIf="row.hasAdSpend" [copyable]="false" [icon]="hasPerspectiveMetadata ? row.description.iconUrlSmall || row.description.iconUrl || PerspectiveDefaultIcon[perspective] : null"
                      [label]="row.description.displayName" [routerLink]="[row.id]" class="clickable mixed-case no-word-break" firstCell>
                <button (click)="togglePinned($event, row.id)" *ngIf="showCompareTabs" [class.pinned]="isPinned(row.id)" class="pin before"><i class="fa-solid fa-thumbtack"></i></button>
              </td>
              <td *ngIf="!row.hasAdSpend" [copyable]="false" [icon]="hasPerspectiveMetadata ? row.description.iconUrlSmall || row.description.iconUrl || PerspectiveDefaultIcon[perspective] : null"
                      [label]="row.description.displayName" class="mixed-case no-word-break" firstCell>
                <button (click)="togglePinned($event, row.id)" *ngIf="showCompareTabs" [class.pinned]="isPinned(row.id)" class="pin before"><i class="fa-solid fa-thumbtack"></i></button>
              </td>
              <!-- METRICS -->
              <td *ngFor="let metric of row?.analysis?.metrics" [class.center]="!isMetricConfigSpend(metric)" [class.two-values]="isMetricConfigSpend(metric)" class="left-line">
                <div *ngIf="isMetricConfigSpend(metric)" class="value-with-change mini-trend-line">
                  <app-metric-current-value *ngIf="isMetricCurrentOrHasTrend(metric)" [config]="metric.config"
                    [value]="metric.current"></app-metric-current-value>
                  <span *ngIf="isMetricDataUnavailable(metric, row)" class="no-ad-spend-label">No ad spend <br /> available</span>
                  <span *ngIf="metric.current" class="subtle-badge">{{row?.analysis?.shareOfSpend || 0 | percent:'1.0-0'}}</span>
                  <highcharts-chart *ngIf="row.metricMiniTrendCharts[metric.config.name]" [Highcharts]="Highcharts"
                    [options]="row.metricMiniTrendCharts[metric.config.name]" oneToOne="true"
                    runOutsideAngular="true"></highcharts-chart>
                </div>
                <div *ngIf="!isMetricConfigSpend(metric)" class="value-with-change mini-trend-line">
                  <app-metric-current-value *ngIf="isMetricCurrentOrHasTrend(metric)" [config]="metric.config" [value]="metric.current"
                    class="value"></app-metric-current-value>
                  <span *ngIf="isMetricDataUnavailable(metric, row)" class="no-ad-spend-label">N/A</span>
                  <span *ngIf="row.metricMiniTrendCharts[metric.config.name]"
                    [innerHTML]="metric.percentageChange | percentageChange:'1.0-0':metric.config.negativeIsGood:metric.config.neutral"
                    class="change"></span>
                  <highcharts-chart *ngIf="row.metricMiniTrendCharts[metric.config.name]" [Highcharts]="Highcharts"
                    [options]="row.metricMiniTrendCharts[metric.config.name]" oneToOne="true"
                    runOutsideAngular="true"></highcharts-chart>
                </div>
              </td>

              <!-- SHARE OF BENEFIT VISUALISATION -->
              <td class="left-line">
                <highcharts-chart *ngIf="row.overallBenefitChart" [Highcharts]="Highcharts" [options]="row.overallBenefitChart" oneToOne="true" runOutsideAngular="true"></highcharts-chart>
              </td>

              <!-- NEXT PERIOD SPEND -->
              <td *ngIf="hasRecommendation" class="left-line">
                  <span *ngIf="!row?.analysis?.bauSpendNextPeriod" class="no-ad-spend-label">N/A</span>
                  <app-metric-current-value class="two-values" [config]="row?.analysis?.bauSpendNextPeriodConfig" [value]="row?.analysis?.bauSpendNextPeriod"></app-metric-current-value>
              </td>

              <!-- RECOMMENDED ACTION -->
              <td *ngIf="hasRecommendation" class="center left-line">
                <span [innerHTML]="row.analysis?.recommendedSpendNextPeriodPercentageChange | recommendedAction:row.analysis?.recommendedSpendNextPeriod:cadence:row.analysis?.bauSpendNextPeriod"></span>
              </td>
            </tr>
            <tr *ngIf="showAsSources" class="sources-total-row">
              <td class="total-label">
                <div class="value label">{{ paidSourcesTotalRow?.description?.displayName }} </div>
              </td>
              <td *ngFor="let metric of paidSourcesTotalRow?.analysis?.metrics" [class.center]="metric.config.name !== 'spend'" [class.two-values]="metric.config.name === 'spend'" class="left-line total-value">
                <div *ngIf="metric.config.name === 'spend'">
                  <span *ngIf="!metric.current" class="no-ad-spend-label">No ad spend <br/> available</span>
                  <app-metric-current-value *ngIf="metric.current || metric.hasTrend" [config]="metric.config" [value]="metric.current" class="value"></app-metric-current-value>
                </div>
                <div *ngIf="metric.config.name !== 'spend'" class="value-with-change mini-trend-line">
                  <app-metric-current-value *ngIf="metric.current || metric.hasTrend" [config]="metric.config" [value]="metric.current" class="value"></app-metric-current-value>
                  <span *ngIf="!metric.current" class="no-ad-spend-label">N/A</span>
                </div>
              </td>
              <td class="left-line total-value">
              </td>
              <td *ngIf="hasRecommendation" class="left-line total-value"></td>
              <td *ngIf="hasRecommendation" class="center left-line total-value"></td>
            </tr>
          </tbody>

          <!--############## Unpaid Sources ####################-->
          <tbody *ngIf="showAsSources" class="unpaid-sources">
            <tr>
              <th colspan="100%" class="top-bottom-padding" class="table-sub-title">
                <label class="label">Unpaid Sources</label>
              </th>
            </tr>
            <tr class="network-row" *ngFor="let row of unpaidSourceRows">
              <!-- NAME -->
              <!-- temporary check to prevent click event if metric does not have any spending -->
              <td *ngIf="row.hasAdSpend" [copyable]="false" [icon]="hasPerspectiveMetadata ? row.description.iconUrlSmall || row.description.iconUrl || PerspectiveDefaultIcon[perspective] : null"
              [label]="row.description.displayName" [routerLink]="[row.id]" class="clickable mixed-case no-word-break" firstCell>
                <button (click)="togglePinned($event, row.id)" *ngIf="showCompareTabs" [class.pinned]="isPinned(row.id)" class="pin before"><i class="fa-solid fa-thumbtack"></i></button>
              </td>
              <td *ngIf="!row.hasAdSpend" [copyable]="false" [icon]="hasPerspectiveMetadata ? row.description.iconUrlSmall || row.description.iconUrl || PerspectiveDefaultIcon[perspective] : null"
              [label]="row.description.displayName" class="mixed-case no-word-break" firstCell>
                <button (click)="togglePinned($event, row.id)" *ngIf="showCompareTabs" [class.pinned]="isPinned(row.id)" class="pin before"><i class="fa-solid fa-thumbtack"></i></button>
              </td>
              <!-- METRICS -->
              <td *ngFor="let metric of row?.analysis?.metrics" [class.center]="metric.config.name !== 'spend'" [class.two-values]="metric.config.name === 'spend'" class="left-line">
                <div *ngIf="metric.config.name === 'spend'">
                  <span *ngIf="!metric.current" class="no-ad-spend-label">No ad spend <br/> available</span>
                  <app-metric-current-value *ngIf="metric.current || metric.hasTrend" [config]="metric.config" [value]="metric.current"></app-metric-current-value>
                  <span *ngIf="metric.current" class="subtle-badge">{{row?.analysis?.shareOfSpend || 0 | percent:'1.0-0'}}</span>
                </div>
                <div *ngIf="metric.config.name !== 'spend'" class="value-with-change mini-trend-line">
                  <span *ngIf="!metric.current && !row.metricMiniTrendCharts[metric.config.name]" class="no-ad-spend-label">N/A</span>
                  <app-metric-current-value *ngIf="metric.current || metric.hasTrend" [config]="metric.config" [value]="metric.current" class="value"></app-metric-current-value>
                  <span *ngIf="row.metricMiniTrendCharts[metric.config.name]" [innerHTML]="metric.percentageChange | percentageChange:'1.0-0':metric.config.negativeIsGood:metric.config.neutral"
                          class="change"></span>
                  <highcharts-chart *ngIf="row.metricMiniTrendCharts[metric.config.name]" [Highcharts]="Highcharts" [options]="row.metricMiniTrendCharts[metric.config.name]" oneToOne="true"
                          runOutsideAngular="true"></highcharts-chart>
                </div>
              </td>

              <!-- SHARE OF BENEFIT VISUALISATION -->
              <td class="left-line">
                <highcharts-chart *ngIf="row.overallBenefitChart" [Highcharts]="Highcharts" [options]="row.overallBenefitChart" oneToOne="true" runOutsideAngular="true"></highcharts-chart>
              </td>

              <!-- NEXT PERIOD SPEND -->
              <td *ngIf="hasRecommendation" class="left-line">
                  <span *ngIf="!row?.analysis?.bauSpendNextPeriod" class="no-ad-spend-label">N/A</span>
                  <app-metric-current-value class="two-values" [config]="row?.analysis?.bauSpendNextPeriodConfig" [value]="row?.analysis?.bauSpendNextPeriod"></app-metric-current-value>
              </td>

              <!-- RECOMMENDED ACTION -->
              <td *ngIf="hasRecommendation" class="center left-line">
                <span [innerHTML]="row.analysis?.recommendedSpendNextPeriodPercentageChange | recommendedAction:row.analysis?.recommendedSpendNextPeriod:cadence:row.analysis?.bauSpendNextPeriod"></span>
              </td>
            </tr>
            <tr *ngIf="showAsSources" class="sources-total-row">
              <td class="total-label">
                <div class="value label">{{ unpaidSourcesTotalRow?.description?.displayName }} </div>
              </td>
              <td *ngFor="let metric of unpaidSourcesTotalRow?.analysis?.metrics" [class.center]="metric.config.name !== 'spend'" [class.two-values]="metric.config.name === 'spend'" class="left-line total-value">
                <div *ngIf="metric.config.name === 'spend'">
                  <span *ngIf="!metric.current" class="no-ad-spend-label">No ad spend <br/> available</span>
                  <app-metric-current-value *ngIf="metric.current || metric.hasTrend" [config]="metric.config" [value]="metric.current" class="value"></app-metric-current-value>
                </div>
                <div *ngIf="metric.config.name !== 'spend'" class="value-with-change mini-trend-line">
                  <app-metric-current-value *ngIf="metric.current || metric.hasTrend" [config]="metric.config" [value]="metric.current" class="value"></app-metric-current-value>
                  <span *ngIf="!metric.current" class="no-ad-spend-label">N/A</span>
                </div>
              </td>
              <td class="left-line total-value">
              </td>
              <td *ngIf="hasRecommendation" class="left-line total-value"></td>
              <td *ngIf="hasRecommendation" class="center left-line total-value"></td>
            </tr>
          </tbody>
          <tfoot *ngIf="morePagesAvailable || !paidSourceRows?.length">
            <tr *ngIf="paidSourceRows?.length && morePagesAvailable">
              <td [colSpan]="2 + columnMetrics.length + (hasRecommendation ? 1 : 0)">
                <div class="pagination-row">
                  <p class="note">
                    <i class="fa-solid fa-circle-info"></i>
                    <span>Column sorting will be enabled when all the data is loaded</span>
                  </p>
                  <div class="main">
                    <p class="pagination-info">Showing {{paidSourceRows.length | number: '1.0-0'}} of {{availableRows | number: '1.0-0'}}</p>
                    <button (click)="loadNextPage()" class="btn btn-secondary">Load more</button>
                  </div>
                  <div class="spacer"></div>
                </div>
              </td>
            </tr>
            <tr *ngIf="!paidSourceRows?.length">
              <td [colSpan]="2 + columnMetrics.length + (hasRecommendation ? 1 : 0)" class="no-data">NO DATA</td>
            </tr>
          </tfoot>
        </table>
      </app-data-table>
    </app-compare-data-table>
  </div>
</section>
