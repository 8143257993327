import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from './auth.service';

/**
 * Tests if the user has the required scope(s) and removes content accordingly if they don't.
 * Equivalent to *ngIf="auth.userHasScopes(Permissions?.somePermission)"
 * Based to a large extent on https://juristr.com/blog/2018/02/angular-permission-directive/
 *
 * Usage syntax:
 * 1. Expose Permissions as a property on the component
 * 2. Must have single permission: *appHasScope="Permissions?.somePermission"
 *    Must have all permissions:   *appHasScope="[Permissions?.permissionA, Permissions?.permissionB]"
 *    Must have any permission:    *appHasScope="[Permissions?.permissionA, Permissions?.permissionB]; any"
 */
@Directive({
  selector: '[appHasScope]'
})
export class HasScopeDirective {

  private requiredScopes: string;

  private allowAny = false;

  public constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly auth: AuthService
  ) { }

  /**
   * Defines the scope(s) the user is required to have. Can either be a string or
   * an array of strings. Always use the Permissions class
   * to define the permissions in preference over magic strings.
   */
  @Input() set appHasScope(scope: string | string[]) {
    this.requiredScopes = (Array.isArray(scope) ? scope.join(' ') : scope) || '';
    this.updateView();
    // Subscribe to changes in the user's scopes and refresh the view
    this.auth.scopes$.subscribe(() => {
      this.updateView();
    });
  }

  // noinspection JSUnusedLocalSymbols
  /**
   * If this value is present (even if it's value is set to null), it puts the directive in "any" mode.
   * The reason it works that way is for clarity of syntax at the point of use: you just suffix the permissions
   * array with "; any"
   */
  @Input() set appHasScopeAny(value: any) {
    this.allowAny = true;
  }

  private updateView() {
    this.viewContainer.clear();
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private checkPermission(): boolean {
    const scopes = this.requiredScopes.split(' ').map(x => x.trim());
    return this.allowAny ? this.auth.userHasAnyScope(scopes) : this.auth.userHasScopes(scopes);
  }

}
