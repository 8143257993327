import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CsvExportService {

    downloadCsv(data: any[], headers: string[], filename = 'data') {
        const csvData = this.convertToCsv(data, headers);
        const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;

        const dummyLink = document.createElement("a");
        if (isSafariBrowser) {
            // If Safari open in new window to
            // save file with random filename.
            dummyLink.setAttribute("target", "_blank");
        }
        dummyLink.setAttribute("href", url);
        dummyLink.setAttribute("download", filename + ".csv");
        dummyLink.style.visibility = "hidden";
        document.body.appendChild(dummyLink);
        dummyLink.click();
        document.body.removeChild(dummyLink);
    }

    private convertToCsv(objArray: any[], headerList: string[]) {
        const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '#,';

        for (let index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = (i + 1) + '';
            for (let index in headerList) {
                let head = headerList[index];
                line += ',' + `"${array[i][head]}"`;
            }
            str += line + '\r\n';
        }
        return str;
    }
}
