import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Resolve, Router } from '@angular/router';
import { cloneDeep, findLastIndex, first, isEqual, merge, orderBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import Highcharts, { SeriesLineOptions, TooltipOptions } from 'highcharts';
import { Options } from 'highcharts';
import moment, { Moment } from 'moment';

import { IAppDto } from '../../api-model/app-dto';
import { INetworkDto } from '../../api-model/network-dto';
import { AuthService } from '../../shared/auth/auth.service';
import { OsIconPipe } from '../../shared/pipes/os-icon.pipe';
import { AnalysisSettingsService } from './analysis-settings.service';
import { ComparePageSettingsService } from './compare-page-settings.service';
import { Perspective, PerspectiveDefaultIcon, PerspectiveDesc } from './perspective';
import { IPerspectiveMetadata } from './perspective-metadata';
import { IRecommendationDto } from 'src/app/api-model/recommendation-dto';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { defaultChartOptions } from 'src/app/default-chart-options';
import { ChartHelperService } from 'src/app/shared/services/chart-helper.service';
import { CadenceDatePipe } from 'src/app/shared/pipes/cadence-date.pipe';
import { IComparisonRowDto } from 'src/app/api-model/comparison-dto';
import { IAttributionMetadataMetricDto } from 'src/app/api-model/attribution-metadata-metric-dto';
import { SortOrder } from 'src/app/api-model/enums/sort-order';
import { RecommendationsDataService } from '../recommendations/recommendations-data.service';
import { DateRange } from 'src/app/shared/date-range';
import { Cadence } from 'src/app/api-model/enums/cadence';
import { CsvExportService } from 'src/app/shared/services/csv-export.service';
import { AttributionQueryHelperService } from 'src/app/shared/services/attribution-query-helper.service';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit, OnDestroy {
  public Highcharts: typeof Highcharts = Highcharts;
  public perspective?: Perspective;
  public app: IAppDto;
  public network?: INetworkDto;
  public perspectiveMetadata: IPerspectiveMetadata[] = [];
  private activeAdvertiserSubscription: Subscription;
  private routeDataSubscription?: Subscription;
  private pageSettingsSubscription?: Subscription;
  private settingsSubscription: Subscription;
  private themeSubscription: Subscription;

  public recommendations: IRecommendationDto = { predicted: [], optimzed: [], recent: [] };
  public recommendationsLineChart?: Options;
  public recommendationsBarChart?: Options;
  public loadingComplete: boolean;
  public selectedRecommendationDate: Moment;
  public selectedRecommendationDateLabel: string;
  private readonly recommendationsResolver: Resolve<IRecommendationDto>;
  public showNetworkLines = true;
  private toggleNetworksLabel = 'Toggle Networks';

  // networks grid
  //public analysis: IComparisonRowDto[] = [];
  public availableRows: number;
  public availablePages: number;
  public pageNumber: number;
  public rows: IRow[] = [];
  public sortBy: SortField = 'name';
  public sortOrder: SortOrder = SortOrder.Ascending;
  public columnMetrics: IAttributionMetadataMetricDto[] = [];

  public constructor(
    public readonly title: Title,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly analysisSettings: AnalysisSettingsService,
    private readonly pageSettingsService: ComparePageSettingsService,
    private readonly themeService: ThemeService,
    private readonly chartHelper: ChartHelperService,
    private readonly cadenceDate: CadenceDatePipe,
    private readonly recommendationsDataService: RecommendationsDataService,
    private readonly csvExportService: CsvExportService,
    private readonly attributionQueryHelperService: AttributionQueryHelperService,
  ) {
    this.recommendationsResolver = inject(this.route.snapshot.routeConfig.resolve.recommendations) as Resolve<IRecommendationDto>;
  }


  public get titleBreadcrumbs() {
    const crumbs = [this.app?.displayName];
    if (this.network) { crumbs.push(this.network?.displayName); }
    crumbs.push(PerspectiveDesc[this.perspective]);
    return crumbs.filter(x => x);
  }

  public get cadence() { return this.analysisSettings.cadence; }

  public get benefitColour() { return '#c44d8a'; }

  public get spendColour() { return this.themeService.dark ? '#38afff' : '#2984c2'; }

  public get morePagesAvailable() { return this.availablePages > this.pageNumber; }

  public get canSort() { return !this.morePagesAvailable; }

  public get SortOrder() { return SortOrder; }

  public get hasPerspectiveMetadata() { return this.perspective !== Perspective.Publishers && this.perspective !== Perspective.Campaigns; }

  public get PerspectiveDefaultIcon() { return PerspectiveDefaultIcon; }

  public toggleSort(field: SortField, defaultOrder: SortOrder = SortOrder.Descending): void {
    if (field === this.sortBy) {
      this.sortOrder = this.sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;
    } else {
      this.sortBy = field;
      this.sortOrder = defaultOrder;
    }
    this.applySorting();
  }

  public sortClass(column: string) {
    return 'sortable' +
      `${(this.sortBy === column ? ` sorted` : '')}` +
      `${(this.sortOrder === SortOrder.Ascending ? ' asc' : ' desc')}`;
  }

  public async export() {
    const dr = this.attributionQueryHelperService.getRecommendationDates(this.analysisSettings.cadence);
    const rawData = await this.getNetworksData(new DateRange(dr.start, dr.end));
    const ds = await this.composeNetworksForExport(rawData.rows);

    if (!ds || ds.length == 0)
      return;

    const headers = Object.keys(Object.values(ds)[0]);
    this.csvExportService.downloadCsv(ds, headers, this.getFileName());
  }

  private getFileName(): string {
    switch (this.analysisSettings.cadence) {
      case Cadence.Monthly:
        return `recommendations_monthly_${this.app.displayName}`
      case Cadence.Weekly:
        return `recommendations_weekly_${this.app.displayName}`
      default:
        return `recommendations_daily_${this.app.displayName}`
    }
  }

  // data fetching and composing
  private async loadData() {
    this.loadingComplete = false;
    this.recommendations = await this.recommendationsResolver.resolve(this.route.snapshot, this.router.routerState.snapshot) as IRecommendationDto;
    this.setSelectedRecommendationDate()
    await this.loadNetworksGrid();
    this.configureCharts();
    this.loadingComplete = true;
  }

  private async getNetworksData(dr: DateRange) {
    return await this.recommendationsDataService.getNetworkMetrics({
      appId: this.app.id,
      idField: 'networkId',
      sortField: 'NETWORK_NAME',
      dateRange: dr
    });
  }

  private async composeNetworksRows(ds: IComparisonRowDto[]) {
    let rows = [];
    if (this.hasPerspectiveMetadata) {
      rows = this.perspectiveMetadata.map(x => {
        const analysis = ds.find(a => a.id === x.id);
        return ({
          id: x.id,
          description: x,
          analysis,
          metricMiniTrendCharts: {}
        } as IRow);
      });
    } else {
      rows = ds.map(a => ({
        id: a.id,
        description: {
          id: a.id,
          displayName: a.id,
          enabled: true,
          active: true
        } as IPerspectiveMetadata,
        analysis: a,
        metricMiniTrendCharts: {}
      } as IRow));
    }

    const settings = await this.pageSettingsService.get(this.app.id);
    const columnMetrics = settings.columnMetrics;
    rows = rows.filter(row => row.analysis?.metrics).map(row => {
      row.analysis.metrics = orderBy(row.analysis?.metrics.filter(x => columnMetrics.includes(x.config.name)) || [], x => columnMetrics.findIndex(m => m === x.config.name));
      return row;
    });

    this.columnMetrics = orderBy(first(ds)?.metrics?.map(x => x.config).filter(x => columnMetrics.includes(x.name)) || [], x => columnMetrics.findIndex(m => m === x.name));
    return rows;
  }

  private async composeNetworksForExport(ds: IComparisonRowDto[]) {
    let rows = this.perspectiveMetadata.map(x => {
      const analysis = ds.find(a => a.id === x.id);
      return ({
        networkId: x.id,
        networkName: x.displayName,
        metrics: analysis?.metrics,
      });
    });

    const settings = await this.pageSettingsService.get(this.app.id);
    const columnMetrics = settings.columnMetrics;
    rows = rows.filter(row => row.metrics).map(row => {
      row.metrics = orderBy(row.metrics.filter(x => columnMetrics.includes(x.config.name)) || [], x => columnMetrics.findIndex(m => m === x.config.name));
      return row;
    });
    let result = [];
    rows.forEach(r => {
      for (let i = 0; i < r.metrics[0].trend.length; i++) {
        let item = {};
        item["networkName"] = r.networkName;
        item["recommendationPeriod"] = this.cadenceDate.transform(r.metrics[0]?.trend[i]?.date, false);
        r.metrics.forEach(m => {
          item[m.config.name] = m.trend[i]?.value ? m.trend[i].value.toFixed(2) : 0;
        })
        result.push(item);
      }
    })
    return result;
  }

  private setSelectedRecommendationDate(dateIndex: number = 0) {
    if (!this.recommendations?.optimzed) return;
    const dates = first(Object.values(first(Object.values(this.recommendations?.optimzed))?.metrics))?.map(x => moment.utc(x.date));
    if (!dates) return;
    this.selectedRecommendationDate = dates[dateIndex];
    this.selectedRecommendationDateLabel = this.cadenceDate.transform(this.selectedRecommendationDate, false)
  }

  // chart configurations
  private configureCharts() {
    this.configureOptimizedLineChart();
    this.configureComparisionBarChart();
  }

  private configureOptimizedLineChart() {
    const self = this;
    const spendMatricName = 'spend';
    if (!self.recommendations?.optimzed?.length) { return; }
    let recent = cloneDeep(self.recommendations.recent);
    let optimized = cloneDeep(self.recommendations.optimzed);

    let dummySpends = []
    recent.find(r => r.metrics)?.metrics[spendMatricName].forEach(v => {
      dummySpends.push({
        date: v.date,
        value: null
      });
    });

    optimized.forEach(on => {
      const match = recent.find(rn => rn.name === on.name);
      if (match?.metrics[spendMatricName]) {
        on.metrics[spendMatricName] = [...match.metrics[spendMatricName], ...on.metrics[spendMatricName]]
      } else {
        on.metrics[spendMatricName] = [...dummySpends, ...on.metrics[spendMatricName]]
      }
    });

    const byNetworkOptimizedSeries = optimized.map((x, i) => ({
      name: x.name,
      data: x.metrics[spendMatricName].map(m => m.value || 0),
      color: defaultChartOptions.colors[i % defaultChartOptions.colors.length],
      lineWidth: 2,
      yAxis: 0,
      dashStyle: 'ShortDash',
      zoneAxis: 'x',
      zones: [{
        value: dummySpends.length
      }, {
        dashStyle: 'Solid'
      }]
    } as (SeriesLineOptions)))

    const totalSpendRecommendation = optimized.map(m => m.metrics[spendMatricName]).reduce((sum, current) => {
      let tmpSum = cloneDeep(sum);
      current.forEach((c, i) => tmpSum[i].value = c.value + tmpSum[i].value);
      return tmpSum;
    });

    const totalSpendRecommendationSeries = {
      name: "Total",
      data: totalSpendRecommendation?.map(m => m.value) || 0,
      color: self.chartHelper.axisLineColour,
      lineWidth: 2,
      yAxis: 1,
      dashStyle: "ShortDash",
      zoneAxis: 'x',
      zones: [{
        value: dummySpends.length
      }, {
        dashStyle: 'Solid'
      }]
    } as (SeriesLineOptions)

    const toggleDummySeries = {
      name: self.toggleNetworksLabel,
      data: [],
      lineWidth: 0,
      marker: {
        symbol: 'url(/assets/images/power-off-solid.svg)'
      },
      yAxis: 1,
    } as (SeriesLineOptions)

    let series = byNetworkOptimizedSeries.concat(totalSpendRecommendationSeries).concat(toggleDummySeries);

    const recentDates = first(Object.values(first(Object.values(recent))?.metrics))?.map(x => moment.utc(x.date));
    const optimizedDates = first(Object.values(first(Object.values(optimized))?.metrics))?.map(x => moment.utc(x.date));
    const dates = recentDates.concat(optimizedDates);

    const categories = dates.map(x => self.cadenceDate.transform(x, false));
    self.recommendationsLineChart = merge(self.chartHelper.timeseriesLineChart(), {
      chart: {
        events: {
          click: function (e) {
            let point = this.series[0].searchPoint(this.pointer.normalize(e), true);
            if (point) {
              let predictedIndex = point.index - dummySpends.length;
              if (predictedIndex < 0 || predictedIndex >= optimizedDates.length - 1) return;
              self.setSelectedRecommendationDate(predictedIndex);
              self.configureComparisionBarChart();
              self.loadNetworksGrid();
              this.xAxis[0].userOptions.plotBands.forEach((pb, idx) => {
                if (idx >= dummySpends.length && idx <= optimizedDates.length) {
                  pb.color = self.chartHelper.plotBandBgColor;
                }
              });
              const thisPlotBand = this.xAxis[0].userOptions.plotBands[point.index];
              if (thisPlotBand && ((self.cadence == Cadence.Monthly) || (self.cadence == Cadence.Weekly))) {
                thisPlotBand.color = 'rgb(217,128,70,0.5)';
                this.xAxis[0].update({});
              }
            }
          }
        }
      },
      xAxis: {
        lineWidth: 0,
        categories: categories,
        labels: { enabled: false },
        min: 0.49,
      },
      yAxis: [{
        min: 0,
        title: {
          text: 'Spend ($)',
          style: { color: self.chartHelper.axisTitleColour }
        },
        gridLineColor: self.chartHelper.yAxisGridLineColour,
        gridLineWidth: 0.5,
        minorGridLineColor: self.chartHelper.minorGridLineColour,
        minorGridLineWidth: 0,
        lineColor: self.chartHelper.axisLineColour,
        labels: {
          style: { color: self.chartHelper.axisLabelColour },
        }
      },
      {
        opposite: true,
        min: 0,
        title: {
          text: 'Total Spend ($)',
          style: { color: self.chartHelper.axisTitleColour }
        },
        gridLineColor: self.chartHelper.yAxisGridLineColour,
        gridLineWidth: 0.5,
        minorGridLineColor: self.chartHelper.minorGridLineColour,
        minorGridLineWidth: 0,
        lineColor: self.chartHelper.axisLineColour,
        labels: {
          style: { color: self.chartHelper.axisLabelColour }
        }
      }],
      tooltip: merge(cloneDeep(self.chartHelper.baseTooltip), {
        crosshairs: false,
        shared: false,
      } as TooltipOptions),
      series: series,
      plotOptions: {
        series: {
          events: {
            legendItemClick: function (e) {
              let chart = this.chart;
              if (this.name === self.toggleNetworksLabel) {
                e.preventDefault();
                self.showNetworkLines = !self.showNetworkLines;
                chart.series.filter(s => s.name.trim().toLocaleLowerCase() !== 'total').forEach((s) => {
                  self.showNetworkLines ? s.show() : s.hide();
                });
              }
            }
          }
        }
      }
    } as Options);

    self.chartHelper.addCadencePlotBands(self.recommendationsLineChart, dates);
    self.chartHelper.addCadenceWeekendPlotBands(self.recommendationsLineChart, dates);
    self.chartHelper.replaceSinglePointLineWithColumn(self.recommendationsLineChart);
    self.plotTodayMarker(self.recommendationsLineChart, dates);
  }

  private plotTodayMarker(options: Options, dates: Moment[]) {
    this.chartHelper.addTodayMarker(options, dates);
    this.chartHelper.addTodayBand(options, dates);
  }

  private configureComparisionBarChart() {
    const self = this;
    if (!self.recommendations.optimzed || !self.recommendations.predicted) return;

    const spendMatricName = "spend"
    const format = "DDMMYYYY"
    const dtStr = this.selectedRecommendationDate.format(format);

    let optimizedNetworkNames = self.recommendations.optimzed.map(n => n.name);
    let predictedNetworkNames = self.recommendations.predicted.map(n => n.name);
    let missingNetworks = optimizedNetworkNames.filter(x => predictedNetworkNames.indexOf(x) < 0).map(x => ({
      networkName: x,
      spend: undefined
    }));

    let optimized = self.recommendations.optimzed.map(r => (
      {
        networkName: r.name,
        spend: r.metrics[spendMatricName].find(mtr => moment.utc(mtr.date).format(format) === dtStr)
      })).filter(a => a.spend).sort((p, q) => p.networkName.localeCompare(q.networkName));

    let predicted = self.recommendations.predicted.map(p =>
    (
      {
        networkName: p.name,
        spend: p.metrics[spendMatricName].find(mtr => moment.utc(mtr.date).format(format) === dtStr)
      })
    ).filter(p => optimized.some(o => o.networkName == p.networkName))
      .concat(missingNetworks).sort((p, q) => p.networkName.localeCompare(q.networkName));

    self.recommendationsBarChart = merge(self.chartHelper.barChart(), {
      title: {
        text: self.cadenceDate.transform(this.selectedRecommendationDate, false),
        style: {
          color: self.chartHelper.axisTitleColour
        }
      },
      chart: {
        height: '400px',
        type: 'column',
        backgroundColor: self.chartHelper.bgColour,
        plotBackgroundColor: self.chartHelper.plotBgColour,
        spacing: [30, 20, 15, 10],
      },
      xAxis: {
        visible: true,
        crosshair: false,
        categories: optimized.map(m => m.networkName)
      },
      yAxis: {
        visible: true,
        min: 0,
        startOnTick: true,
        endOnTick: true,
        title: {
          text: 'Spend ($)',
          style: { color: self.chartHelper.axisTitleColour }
        },
      },
      legend: { enabled: true },
      series: [{
        data: predicted.map(m => m.spend?.value || 0),
        name: 'Predicted BAU',
        borderWidth: 0,
      }, {
        data: optimized.map(m => m.spend?.value || 0),
        name: 'Recommended',
        borderWidth: 0,
      }],
      plotOptions: {
        column: {
          pointPadding: -0.20,
          borderWidth: 0
        }
      },
      tooltip: merge(cloneDeep(self.chartHelper.baseTooltip), {
        crosshairs: false,
        useHTML: true,
        className: "bar-chart-tooltip",
      } as TooltipOptions),
    } as Options);
  }

  // networks grid helpers
  private async loadNetworksGrid() {
    const data = await this.getNetworksData(new DateRange(this.selectedRecommendationDate, this.selectedRecommendationDate));

    this.pageNumber = data.pageNumber;
    this.availablePages = data.availablePages;
    this.availableRows = data.availableRows;

    this.rows = await this.composeNetworksRows(data.rows.filter(r => r.metrics.some(m => m.config.name == 'spend' && m.current)));
    this.applySorting();
  }

  private applySorting() {
    const sortOrderString = this.sortOrder === SortOrder.Ascending ? 'asc' : 'desc';
    let selector: (metrics: IComparisonRowDto) => number;

    switch (this.sortBy) {
      case 'name':
        this.rows = orderBy(this.rows, [x => x?.description.displayName.toLowerCase()], [sortOrderString, 'desc']);
        return;
      case 'share-of-benefit':
        selector = (x: IComparisonRowDto) => x?.shareOfBenefit ?? -1;
        break;
      case 'recommended-spend':
        selector = (x: IComparisonRowDto) => x?.recommendedSpendNextPeriodPercentageChange ?? -1;
        break;
      default:
        selector = (x: IComparisonRowDto) => x?.metrics.find(m => m.config.name === this.sortBy)?.current ?? -1;
        break;
    }

    this.rows = orderBy(
      this.rows,
      [
        x => selector(x.analysis),
        x => x.analysis[this.sortBy]?.current
      ],
      [sortOrderString]);
  }

  // events
  public ngOnInit(): void {
    this.activeAdvertiserSubscription = this.auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(async () => {
      this.router.navigate(['/']);
    });

    this.pageSettingsSubscription = this.pageSettingsService.settings$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(async () => this.loadData());

    this.routeDataSubscription = this.route.data.subscribe(d => {
      this.app = d.app;
      this.network = d.network;
      this.perspectiveMetadata = d.perspectiveMetadata || [];
      this.perspective = d.perspective;
      this.recommendations = d.recommendations;

      this.settingsSubscription?.unsubscribe();
      this.analysisSettings.settings$.pipe(skip(1), distinctUntilChanged((x, y) =>
        isEqual(x.regionId, y.regionId) &&
        isEqual(x.networkId, y.networkId) &&
        isEqual(x.cadence, y.cadence) &&
        isEqual(x.smoothingPeriod, y.smoothingPeriod) &&
        isEqual(x.dateRange, y.dateRange)))
        .subscribe(async () => this.loadData());
      this.loadData();
    });

    this.themeSubscription = this.themeService.changed$.subscribe(_ => this.configureCharts());
  }

  public ngOnDestroy(): void {
    this.activeAdvertiserSubscription?.unsubscribe();
    this.pageSettingsSubscription?.unsubscribe();
    this.routeDataSubscription?.unsubscribe();
    this.settingsSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }
}

interface IRowCharts {
  metricMiniTrendCharts: {
    [name: string]: Options
  };
}

interface IRow extends IRowCharts {
  id: string;
  description: IPerspectiveMetadata;
  analysis: IComparisonRowDto;
}

type SortField = 'name' | 'share-of-benefit' | 'recommended-spend' | 'spend' | string;
