export enum Perspective {
  Networks,
  Regions,
  Publishers,
  Campaigns,
  Recommendations
}

export const PerspectiveDesc = [
  // 'Networks',
  'Sources',
  'Regions',
  'Publishers',
  'Campaigns',
  'Recommendations'
];

export const PerspectiveSingularDesc = [
  // 'Network',
  'Source',
  'Region',
  'Publisher',
  'Campaign',
  'Recommendations'
];

export const PerspectiveDefaultIcon = [
  '/assets/images/default-network-icon.svg',
  '/assets/images/default-region-icon.svg',
  '/assets/images/default-publisher-icon.svg',
  '/assets/images/default-campaign-icon.svg'
];

export const PerspectiveSlug = [
  'networks',
  'regions',
  'publishers',
  'campaigns',
  'recommendations'
];

