import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Resolve, Router } from '@angular/router';
import Highcharts, { Options } from 'highcharts';
import { cloneDeep, first, isEqual, max, merge, orderBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { IAppDto } from '../../api-model/app-dto';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { IComparisonDto, IComparisonRowDto } from '../../api-model/comparison-dto';
import { SortOrder } from '../../api-model/enums/sort-order';
import { INetworkDto } from '../../api-model/network-dto';
import { AuthService } from '../../shared/auth/auth.service';
import { OsIconPipe } from '../../shared/pipes/os-icon.pipe';
import { ChartHelperService } from '../../shared/services/chart-helper.service';
import { ThemeService } from '../../shared/services/theme.service';
import { AppsAnalysisMetadataService } from '../apps/apps-analysis-metadata.service';
import { AnalysisDataService } from './analysis-data.service';
import { AnalysisSettingsService } from './analysis-settings.service';
import { ComparePageSettingsService } from './compare-page-settings.service';
import { Perspective, PerspectiveDefaultIcon, PerspectiveDesc, PerspectiveSingularDesc } from './perspective';
import { IPerspectiveMetadata } from './perspective-metadata';
import { PinningService } from './pinning.service';
import { IMetricDto } from 'src/app/api-model/metric-dto';
import { Cadence, CadencePeriodPluralDesc } from 'src/app/api-model/enums/cadence';
import { ExportOptions } from 'src/app/api-model/enums/export-options';
import { CsvExportService } from 'src/app/shared/services/csv-export.service';
import moment from 'moment';
import { IAttributionMetricsResultDto } from 'src/app/api-model/attribution-metrics-response-dto';
import { AttributionQueryHelperService } from 'src/app/shared/services/attribution-query-helper.service';
import { environment } from 'src/environments/environment';
import { IAppMetricsDto } from 'src/app/api-model/app-metrics-dto';
import { ITrendDateDto } from 'src/app/api-model/trend-date-dto';

interface IRowCharts {
  overallBenefitChart?: Options;
  metricMiniTrendCharts: {
    [name: string]: Options
  };
}

interface IRow extends IRowCharts {
  id: string;
  description: IPerspectiveMetadata;
  analysis: IComparisonRowDto;
  hasAdSpend?: boolean;
  isOrganic?: boolean;
}

type SortField = 'name' | 'share-of-benefit' | 'recommended-spend' | 'spend' | string;

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit, OnDestroy {

  public Highcharts: typeof Highcharts = Highcharts;
  public paidSourcesAnalysis: IComparisonRowDto[];
  public unpaidSourcesAnalysis: IComparisonRowDto[];
  public availableRows: number;
  public availablePages: number;
  public pageNumber: number;
  public expanded = false;
  public perspective?: Perspective;
  public app: IAppDto;
  public network?: INetworkDto;
  public perspectiveMetadata: IPerspectiveMetadata[] = [];
  public paidSourceRows: IRow[] = [];
  public unpaidSourceRows: IRow[] = [];
  public paidSourcesTotalRow: IRow;
  public unpaidSourcesTotalRow: IRow;
  public grandTotalRow: IRow;
  public aggrMetricsCurrentValues: IAppMetricsDto;
  public showAsSources: Boolean;
  public sortBy: SortField = 'spend';
  public sortOrder: SortOrder = SortOrder.Descending;
  public columnMetrics: IAttributionMetadataMetricDto[] = [];
  public benefitMetric: IAttributionMetadataMetricDto;
  public readonly Perspective = Perspective;
  private activeAdvertiserSubscription: Subscription;
  private routeDataSubscription?: Subscription;
  private pageSettingsSubscription?: Subscription;
  private settingsSubscription: Subscription;
  private pinsSubscription: Subscription;
  private themeSubscription: Subscription;
  private analysisResolver: Resolve<IComparisonDto>;

  public constructor(
    public readonly title: Title,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly osIcon: OsIconPipe,
    private readonly router: Router,
    private readonly analysisSettings: AnalysisSettingsService,
    private readonly chartHelper: ChartHelperService,
    private readonly pins: PinningService,
    private readonly theme: ThemeService,
    private readonly pageSettingsService: ComparePageSettingsService,
    private readonly analysisDataService: AnalysisDataService,
    private readonly attributionQueryHelperService: AttributionQueryHelperService,
    private readonly appsAnalysisMetadataService: AppsAnalysisMetadataService,
    private readonly csvExportService: CsvExportService
  ) {
    this.analysisResolver = inject(this.route.snapshot.routeConfig.resolve.analysis) as Resolve<IComparisonDto>;
  }

  public get hasRecommendation() { return this.perspective === Perspective.Networks; }

  public get iconUrls() {
    return [this.app?.iconUrlSmall, this.osIcon.transform(this.app?.operatingSystem), this.network?.iconUrlSmall].filter(x => x);
  }

  public get titleBreadcrumbs() {
    const crumbs = [this.app?.displayName];
    if (this.network) { crumbs.push(this.network?.displayName); }
    crumbs.push(PerspectiveDesc[this.perspective]);
    return crumbs.filter(x => x);
  }

  public get SortOrder() { return SortOrder; }

  public get PerspectiveSingularDesc() { return PerspectiveSingularDesc; }

  public get PerspectiveDefaultIcon() { return PerspectiveDefaultIcon; }

  public get spendColour() { return this.theme.dark ? '#38afff' : '#2984c2'; }

  public get benefitColour() { return '#c44d8a'; }

  public get showRegionFilter() { return this.perspective !== Perspective.Regions; }

  public get showNetworkFilter() { return this.perspective === Perspective.Regions; }

  public get showCompareTabs() { return this.perspective !== Perspective.Publishers && this.perspective !== Perspective.Campaigns; }

  public get showNetworkDrilldownTabs() { return this.perspective === Perspective.Publishers || this.perspective === Perspective.Campaigns; }

  public get hasPerspectiveMetadata() { return this.perspective !== Perspective.Publishers && this.perspective !== Perspective.Campaigns; }

  public get cadence() { return this.analysisSettings.cadence; }

  public get morePagesAvailable() { return this.availablePages > this.pageNumber; }

  public get canSort() { return !this.morePagesAvailable; }

  public get getRecommendationNextPeriod() {
    let nextPeriodDates = this.attributionQueryHelperService.getNextPeriodDates(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    let text = '';
    switch (this.analysisSettings.cadence) {
      case Cadence.Monthly:
        text = `Month of ${nextPeriodDates.start.utc().format('MMMM, YYYY')}`;
        break;
      case Cadence.Weekly:
        text = `Week commencing on ${nextPeriodDates.start.utc().format('ddd, ll')}`;
        break;
      case Cadence.Daily:
        text = nextPeriodDates.start.utc().format('ddd, ll');
        break;
    }
    return text;
  }

  public ngOnInit(): void {
    this.activeAdvertiserSubscription = this.auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(async () => {
      // When the user's active advertiser changes, redirect back to the dashboard
      this.router.navigate(['/']);
    });

    this.pageSettingsSubscription = this.pageSettingsService.settings$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(async () => this.loadData(true));

    this.routeDataSubscription = this.route.data.subscribe(d => {
      this.app = d.app;
      this.network = d.network;
      this.perspectiveMetadata = d.perspectiveMetadata || [];
      this.perspective = d.perspective;

      this.parseComparisonData(d.analysis);

      this.settingsSubscription?.unsubscribe();
      this.analysisSettings.settings$.pipe(skip(1), distinctUntilChanged((x, y) =>
        isEqual(x.regionId, y.regionId) &&
        isEqual(x.networkId, y.networkId) &&
        isEqual(x.cadence, y.cadence) &&
        isEqual(x.smoothingPeriod, y.smoothingPeriod) &&
        isEqual(x.dateRange, y.dateRange)))
        .subscribe(async () => this.loadData(true));

      this.loadData();
    });

    this.themeSubscription = this.theme.changed$.subscribe(_ => this.configureCharts());

    this.pinsSubscription = this.pins.pins$.pipe(
      skip(1),
      distinctUntilChanged((x, y) => isEqual(x[Perspective[this.perspective]], y[Perspective[this.perspective]]))
    ).subscribe(async () => this.applySorting());
  }

  public ngOnDestroy(): void {
    this.activeAdvertiserSubscription?.unsubscribe();
    this.pageSettingsSubscription?.unsubscribe();
    this.routeDataSubscription?.unsubscribe();
    this.settingsSubscription?.unsubscribe();
    this.pinsSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }

  public isMetricConfigSpend(metric: any) : boolean {
    return metric.config.name === 'spend';
  }

  public isMetricCurrentOrHasTrend(metric: any) : boolean {
    return metric.current || metric.hasTrend;
  }

  public isMetricDataUnavailable(metric: any, row: any) : boolean {
    return !metric.current && !row.metricMiniTrendCharts[metric.config.name]
  }
  

  public async loadData(reloadAnalysis = false) {
    if (reloadAnalysis) {
      const data = await this.analysisResolver.resolve(this.route.snapshot, this.router.routerState.snapshot) as IComparisonDto;
      const settings = await this.pageSettingsService.get();
      const unpaidSourcesData = await this.analysisDataService.getUnpaidSourcesComparisonMetrics(this.app.id, settings.columnMetrics.concat(settings.benefitMetric));
      this.aggrMetricsCurrentValues = await this.analysisDataService.getMetricsByAppId(this.app.id, settings.columnMetrics.concat(settings.benefitMetric));
      this.parseComparisonData(data);
      this.parseUnpaidSourcesData(unpaidSourcesData)
    }

    this.showAsSources = this.perspective === Perspective.Networks;
    await this.composeRows();
    this.configureCharts();
  }

  public async loadNextPage() {
    const settings = await this.pageSettingsService.get();

    let idField: string;
    let sortField: string;

    switch (this.perspective) {
      case Perspective.Networks:
        idField = 'networkId';
        sortField = 'NETWORK_NAME';
        break;
      case Perspective.Regions:
        idField = 'regionId';
        sortField = 'REGION_NAME';
        break;
      case Perspective.Publishers:
        idField = 'publisherName';
        sortField = 'PUBLISHER_NAME';
        break;
      case Perspective.Campaigns:
        idField = 'campaignName';
        sortField = 'CAMPAIGN_NAME';
        break;
    }

    const data = await this.analysisDataService.getComparisonMetrics({
      appId: this.app.id,
      idField,
      sortField,
      metricNames: settings.columnMetrics.concat(settings.benefitMetric),
      pageNumber: this.pageNumber + 1,
      useRegionFilter: this.perspective !== Perspective.Regions,
      useNetworkFilter: this.perspective === Perspective.Regions,
      networkId: this.route.snapshot.params.networkId
    });

    this.parseComparisonData(data, false);
    await this.loadData();
  }

  public isPinned(id: string) { return this.pins.isPinned(id, this.perspective); }

  public togglePinned(event: MouseEvent, id: string) {
    event.stopPropagation();
    this.pins.toggle(id, this.perspective);
    this.applySorting();
  }

  public toggleSort(field: SortField, defaultOrder: SortOrder = SortOrder.Descending): void {
    if (field === this.sortBy) {
      this.sortOrder = this.sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;
    } else {
      this.sortBy = field;
      this.sortOrder = defaultOrder;
    }
    this.applySorting();
  }

  public sortClass(column: string) {
    return 'sortable' +
      `${(this.sortBy === column ? ` sorted` : '')}` +
      `${(this.sortOrder === SortOrder.Ascending ? ' asc' : ' desc')}`;
  }

  public async exportAllNetworks(exportOption: ExportOptions) {
    const settings = await this.pageSettingsService.get();
    const attrs = await this.analysisDataService.getComparisonMetricsForExport({
      appId: this.app.id,
      sortField: "NETWORK_NAME",
      sortDirection: "ASC",
      metricNames: settings.columnMetrics.concat(settings.benefitMetric),
      exportOption
    });

    let ds = await this.prepareDatasetForExport(attrs);
    let headers = Object.keys(Object.values(ds)[0]);
    this.csvExportService.downloadCsv(ds, headers, this.getFileName(exportOption));
  }

  public async exportCurrentNetwork() {
    const settings = await this.pageSettingsService.get();
    let attrs: {
      [date: string]: IAttributionMetricsResultDto[];
    }

    const exportOption = this.getExportOptionByPerspective(this.perspective);

    switch (this.perspective) {
      case Perspective.Campaigns:
        attrs = await this.analysisDataService.getComparisonMetricsForExport({
          appId: this.app.id,
          sortField: "CAMPAIGN_NAME",
          sortDirection: "ASC",
          metricNames: settings.columnMetrics.concat(settings.benefitMetric),
          exportOption: exportOption,
          networkId: this.network.id
        })
        break;
      case Perspective.Publishers:
        attrs = await this.analysisDataService.getComparisonMetricsForExport({
          appId: this.app.id,
          sortField: "PUBLISHER_NAME",
          sortDirection: "ASC",
          metricNames: settings.columnMetrics.concat(settings.benefitMetric),
          exportOption: exportOption,
          networkId: this.network.id
        })
        break;
      default:
        attrs = await this.analysisDataService.getComparisonMetricsForExport({
          appId: this.app.id,
          sortField: "NETWORK_NAME",
          sortDirection: "ASC",
          metricNames: settings.columnMetrics.concat(settings.benefitMetric),
          exportOption: exportOption,
          networkId: this.network.id
        })
        break;
    }

    let ds = await this.prepareDatasetForExport(attrs);
    let headers = Object.keys(Object.values(ds)[0]);
    this.csvExportService.downloadCsv(ds, headers, this.getFileName(exportOption));
  }

  private getExportOptionByPerspective(perspective: Perspective): ExportOptions {
    switch (perspective) {
      case Perspective.Campaigns:
        return ExportOptions.ByNetworkPublisherCampaign;
      case Perspective.Publishers:
        return ExportOptions.ByNetworkPublisher;
      default:
        return ExportOptions.ByNetwork;
    }
  }

  private async prepareDatasetForExport(dataset: any) {
    let keys = Object.keys(dataset).sort(function (a, b) { return Date.parse(a) - Date.parse(b) });
    let rows = [];

    keys.forEach(key => {
      dataset[key].forEach(attr => {
        let row = {};
        for (const prop in attr) {
          if (prop == "networkId")
            continue;
          if (prop == "date") {
            row["date"] = moment(attr[prop]).format('YYYY-MM-DD');
            continue;
          }
          if (typeof attr[prop] === 'number') {
            row[prop] = attr[prop].toFixed(2);
            continue;
          }
          row[prop] = attr[prop]
        }
        rows.push(row);
      });
    });
    return rows;
  }

  private getFileName(exportOption: ExportOptions): string {
    const dr = `${this.analysisSettings.dateRange.start.format("YYYYMMDD")}_${this.analysisSettings.dateRange.end.format("YYYYMMDD")}`;
    let filter = ""
    switch (exportOption) {
      case ExportOptions.ByNetworkPublisherCampaign:
        filter = "Network_Publisher_Campaign";
        break;
      case ExportOptions.ByNetworkPublisher:
        filter = "Network_Publisher";
        break;
      default:
        filter = "Network"
        break;
    }

    let smoothing = "";
    if (this.analysisSettings.smoothingPeriod) {
      smoothing = `_${this.analysisSettings.smoothingPeriod}${CadencePeriodPluralDesc[this.analysisSettings.cadence]}`
    }
    return `${this.app.advertiserName}_${this.app.displayName}_${filter}_${dr}${smoothing}`.replace(/ /g, "_");
  }

  private parseComparisonData(data: IComparisonDto, replace = true) {
    if (replace) {
      this.paidSourcesAnalysis = data.rows;
    } else {
      this.paidSourcesAnalysis.push(...data.rows);
    }

    this.pageNumber = data.pageNumber;
    this.availablePages = data.availablePages;
    this.availableRows = data.availableRows;
  }

  private parseUnpaidSourcesData(data: IComparisonDto) {
    this.unpaidSourcesAnalysis = data.rows;
  }

  private async composeRows() {
    const settings = await this.pageSettingsService.get(this.app.id);
    const allMetrics = await this.appsAnalysisMetadataService.getById(this.app.id);
    this.benefitMetric = allMetrics.metrics.find(m => m.name === settings.benefitMetric);

    this.composePaidSourcesRows(settings.columnMetrics);
    this.composeUnpaidSourcesRows(settings.columnMetrics);

    this.composePaidSourcesTotalRow(settings.columnMetrics);
    this.composeUnpaidSourcesTotalRow(settings.columnMetrics);

    this.composeGrandTotalRow(settings.columnMetrics);

    this.columnMetrics = orderBy(first(this.paidSourcesAnalysis)?.metrics?.map(x => x.config).filter(x => settings.columnMetrics.includes(x.name)) || [], x => settings.columnMetrics.findIndex(m => m === x.name));
    this.applySorting();
  }

  private composePaidSourcesRows(columnMetrics: string[]) {
    if (this.hasPerspectiveMetadata) {
      this.paidSourceRows = this.perspectiveMetadata.map(x => {
        const analysis = this.paidSourcesAnalysis.find(a => a.id === x.id);
        return ({
          id: x.id,
          description: x,
          analysis,
          metricMiniTrendCharts: {},
          hasAdSpend: this.hasAdSpend(analysis?.metrics),
          isOrganic: x.id === environment.organicNetworkId
        } as IRow);
      }).filter(row => row.analysis?.metrics);
    } else {
      this.paidSourceRows = this.paidSourcesAnalysis.map(a => ({
        id: a.id,
        description: {
          id: a.id,
          displayName: a.id,
          enabled: true,
          active: true
        } as IPerspectiveMetadata,
        analysis: a,
        metricMiniTrendCharts: {}
      } as IRow)).filter(row => row.analysis?.metrics);
    }
    this.paidSourceRows = this.paidSourceRows.map(row => {
      row.analysis.metrics = orderBy(row.analysis?.metrics.filter(x => columnMetrics.includes(x.config.name)) || [], x => columnMetrics.findIndex(m => m === x.config.name));
      return row;
    });
  }

  private composeUnpaidSourcesRows(columnMetrics: string[]) {
    this.unpaidSourceRows = this.unpaidSourcesAnalysis?.map(a => ({
      id: a.id,
      description: {
        id: a.id,
        displayName: a.id,
        enabled: true,
        active: true
      } as IPerspectiveMetadata,
      analysis: a,
      metricMiniTrendCharts: {}
    } as IRow)).filter(row => row.analysis?.metrics);

    this.unpaidSourceRows = this.unpaidSourceRows?.map(row => {
      row.analysis.metrics = orderBy(row.analysis?.metrics.filter(x => columnMetrics.includes(x.config.name)) || [], x => columnMetrics.findIndex(m => m === x.config.name));
      return row;
    });
  }

  private composePaidSourcesTotalRow(columnMetrics: string[]) {
    if (!this.aggrMetricsCurrentValues || !this.paidSourceRows || this.paidSourceRows.length == 0) return;

    let metrics = [...this.paidSourceRows[0]?.analysis.metrics].map(m => ({
      config: { ...m.config },
      current: 0,
    } as IMetricDto));

    this.paidSourcesTotalRow = {
      id: undefined,
      analysis: {
        id: undefined,
        metrics: metrics,
        bauSpendNextPeriod: 0,
        bauSpendNextPeriodConfig: {},
        recommendedSpendNextPeriod: 0,
        recommendedSpendNextPeriodPercentageChange: 0,
        shareOfBenefit: 0,
        shareOfSpend: 0,
      },
      description: {
        displayName: "Paid Sources Total",
        active: true,
        enabled: true,
      },
      metricMiniTrendCharts: {}
    }

    this.paidSourcesTotalRow.analysis.metrics.forEach(m => {
      if (m.config.name == 'installs') {
        const match = this.aggrMetricsCurrentValues.metrics.find(am => am.config.name === "paidInstalls");
        if (match) {
          m.current = match.current;
        }
        return;
      }

      if (m.config.name == 'firstTimeConversions') {
        const match = this.aggrMetricsCurrentValues.metrics.find(am => am.config.name === "paidFirstTimeConversions");
        if (match) {
          m.current = match.current;
        }
        return;
      }

      if (m.config.name == 'revenue365d') {
        const match = this.aggrMetricsCurrentValues.metrics.find(am => am.config.name === "paidRevenue365d");
        if (match) {
          m.current = match.current;
        }
        return;
      }

      if (m.config.name == 'revenue7d') {
        const match = this.aggrMetricsCurrentValues.metrics.find(am => am.config.name === "paidRevenue7d");
        if (match) {
          m.current = match.current;
        }
        return;
      }

      if (m.config.name == 'firstTimeConversions365d') {
        const match = this.aggrMetricsCurrentValues.metrics.find(am => am.config.name === "paidFirstTimeConversions365d");
        if (match) {
          m.current = match.current;
        }
        return;
      }

      if (m.config.name == 'payments365d') {
        const match = this.aggrMetricsCurrentValues.metrics.find(am => am.config.name === "paidPayments365d");
        if (match) {
          m.current = match.current;
        }
        return;
      }

      const am = this.aggrMetricsCurrentValues.metrics.find(am => am.config.name === m.config.name);
      if (am) {
        m.current = am.current;
      }
    });
    this.paidSourcesTotalRow.analysis.metrics = orderBy(this.paidSourcesTotalRow.analysis?.metrics.filter(x => columnMetrics.includes(x.config.name)) || [], x => columnMetrics.findIndex(m => m === x.config.name));
  }

  private composeUnpaidSourcesTotalRow(columnMetrics: string[]) {
    if (!this.unpaidSourceRows || !this.unpaidSourceRows || this.unpaidSourceRows.length == 0) return;
    let metrics = [...this.unpaidSourceRows[0]?.analysis.metrics].map(m => ({
      config: { ...m.config },
      current: 0,
    } as IMetricDto));

    this.unpaidSourcesTotalRow = {
      id: undefined,
      analysis: {
        id: undefined,
        metrics: metrics,
        bauSpendNextPeriod: 0,
        bauSpendNextPeriodConfig: {},
        recommendedSpendNextPeriod: 0,
        recommendedSpendNextPeriodPercentageChange: 0,
        shareOfBenefit: 0,
        shareOfSpend: 0,
      },
      description: {
        displayName: "Unpaid Sources Total",
        active: true,
        enabled: true,
      },
      metricMiniTrendCharts: {}
    }

    this.unpaidSourceRows.forEach(row => {
      row.analysis.metrics.forEach(m => {
        const tm = this.unpaidSourcesTotalRow.analysis.metrics.find(x => x.config.name == m.config.name);
        if (m.config.summable) {
          tm.current += m.current;
        }
      });
    });

    this.unpaidSourcesTotalRow.analysis.metrics = orderBy(this.unpaidSourcesTotalRow.analysis?.metrics.filter(x => columnMetrics.includes(x.config.name)) || [], x => columnMetrics.findIndex(m => m === x.config.name));
  }

  private composeGrandTotalRow(columnMetrics: string[]) {
    if (!this.paidSourcesTotalRow || !this.unpaidSourcesTotalRow) return;
    let metrics = this.paidSourcesTotalRow.analysis.metrics.map(m => ({
      config: { ...m.config },
      current: 0,
    } as IMetricDto));

    this.grandTotalRow = {
      id: undefined,
      analysis: {
        id: undefined,
        metrics: metrics,
        bauSpendNextPeriod: 0,
        bauSpendNextPeriodConfig: {},
        recommendedSpendNextPeriod: 0,
        recommendedSpendNextPeriodPercentageChange: 0,
        shareOfBenefit: 0,
        shareOfSpend: 0,
      },
      description: {
        displayName: "Grand Total",
        active: true,
        enabled: true,
      },
      metricMiniTrendCharts: {}
    }

    this.updateGrandTotalRowMetrics();

    this.grandTotalRow.analysis.metrics = orderBy(this.grandTotalRow.analysis?.metrics.filter(x => columnMetrics.includes(x.config.name)) || [], x => columnMetrics.findIndex(m => m === x.config.name));
  }

  private updateGrandTotalRowMetrics() {
    this.grandTotalRow.analysis.metrics.forEach(m => {
      this.updateForSummable(m);
      this.updateForAllFields(m);
    });
  }

  private updateForAllFields(m: IMetricDto) {
    const metricsMapper = {
      cpa: 'globalcpa',
      cpi: 'globalcpi',
      roas: 'globalroas',
      installs: 'installs'
    };
    const targetConfigName = metricsMapper[m.config.name] || m.config.name;
    const resultItem = this.aggrMetricsCurrentValues.metrics.find(
      am => am.config.name === targetConfigName
    );
    if (resultItem) {
      m.current = resultItem?.current || 0;
      m.trend = this.getFilteredTrendListForTotal(resultItem?.trend);
      m.percentageChange = resultItem.percentageChange;
    }
  }

  private getFilteredTrendListForTotal(trendList: any): ITrendDateDto[] {
    return trendList && trendList.filter(item => {
      const itemDate = moment.utc(item.date);
      const isWithinRange = itemDate.isSameOrAfter(this.analysisSettings.dateRange.start) && itemDate.isSameOrBefore(this.analysisSettings.dateRange.end);
      return isWithinRange;
    });
  }

  private updateForSummable(m: IMetricDto) {
    const paid = this.paidSourcesTotalRow?.analysis?.metrics.find(x => x.config.name == m.config.name);
    const unpaid = this.unpaidSourcesTotalRow?.analysis?.metrics.find(x => x.config.name == m.config.name);
    if (m.config.summable) {
      m.current = (paid?.current || 0) + (unpaid?.current || 0);
    }
  }

  private hasAdSpend(metrics: IMetricDto[]) {
    if (!metrics || metrics.length == 0)
      return false
    let spending = metrics.find(m => m.config.name === 'spend');
    return spending?.current != 0
  }

  private applySorting() {
    const sortOrderString = this.sortOrder === SortOrder.Ascending ? 'asc' : 'desc';
    let selector: (metrics: IComparisonRowDto) => number;

    switch (this.sortBy) {
      case 'name':
        this.paidSourceRows = orderBy(this.paidSourceRows, [x => this.isPinned(x.id), x => x?.description.displayName.toLowerCase()], ['desc', sortOrderString]);
        return;
      case 'share-of-benefit':
        selector = (x: IComparisonRowDto) => x?.shareOfBenefit ?? -1;
        break;
      case 'recommended-spend':
        selector = (x: IComparisonRowDto) => x?.recommendedSpendNextPeriodPercentageChange ?? -1;
        break;
      default:
        selector = (x: IComparisonRowDto) => x?.metrics.find(m => m.config.name === this.sortBy)?.current ?? -1;
        break;
    }

    this.paidSourceRows = orderBy(
      this.paidSourceRows,
      [
        x => this.isPinned(x.id),
        x => selector(x.analysis),
        x => x.analysis['spend']?.current
      ],
      ['desc', sortOrderString, sortOrderString]);
  }

  private configureCharts() {
    if (!this.paidSourcesAnalysis) { return; }
    const overallBenefitMaxY = max(this.paidSourcesAnalysis.map(x => Math.max(x.shareOfBenefit, x.shareOfSpend)));

    this.paidSourceRows?.forEach(row => {
      row.metricMiniTrendCharts = {};
      row.analysis.metrics.forEach(metric => {
        row.metricMiniTrendCharts[metric.config.name] = this.chartHelper.miniTrendLine({
          metric: metric.config,
          data: metric.trend.filter(x => x.hasAttributionData)
        });
        if (row.metricMiniTrendCharts[metric.config.name]) {
          row.metricMiniTrendCharts[metric.config.name] = merge(row.metricMiniTrendCharts[metric.config.name], { chart: { width: 120 } } as Options);
        }
      });

      this.benefitChart(row, overallBenefitMaxY);
    });
    this.configureChartsForGrandTotal();
  }

  private configureChartsForGrandTotal() {
    if(!this.grandTotalRow) return;
    const row = this.grandTotalRow;
    row.metricMiniTrendCharts = {};
    row.analysis.metrics.forEach(metric => {
      row.metricMiniTrendCharts[metric.config.name] = this.chartHelper.miniTrendLine({
        metric: metric.config,
        data: metric.trend ? metric.trend.filter(x => x.hasAttributionData) : []
      });
      if (row.metricMiniTrendCharts[metric.config.name]) {
        row.metricMiniTrendCharts[metric.config.name] = merge(row.metricMiniTrendCharts[metric.config.name], { chart: { width: 120 } } as Options);
      }
    });
  }

  private benefitChart(row: IRow, maxY: number) {
    row.overallBenefitChart = merge(this.chartHelper.barChart(), {
      chart: {
        plotBackgroundColor: 'transparent',
        height: 20,
        width: 350,
        spacing: [0, 0, 0, 0]
      },
      xAxis: { visible: false, crosshair: false },
      yAxis: { visible: false, min: 0, max: maxY, startOnTick: false, endOnTick: false },
      legend: { enabled: false },
      tooltip: { enabled: false },
      series: [{
        data: [row.analysis.shareOfSpend],
        name: 'Share of Spend',
        borderWidth: 0,
        color: this.spendColour
      }, {
        data: [row.analysis.shareOfBenefit],
        name: 'Share of Benefit',
        borderWidth: 0,
        color: this.benefitColour
      }],
      plotOptions: {
        series: { enableMouseTracking: false }
      }
    } as Options);
  }
}
