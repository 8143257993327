import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Resolve, Router } from '@angular/router';
import Highcharts, { Options, SeriesAreasplinerangeOptions, SeriesScatterOptions, SeriesSplineOptions, XAxisOptions } from 'highcharts';
import { chunk, cloneDeep, first, flatMap, isEqual, merge, uniq } from 'lodash-es';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { IAppDto } from '../../api-model/app-dto';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { ICostCurvesDto } from '../../api-model/cost-curves-dto';
import { IDrilldownDto } from '../../api-model/drilldown-dto';
import { Cadence } from '../../api-model/enums/cadence';
import { CostCurvesMetricFormat } from '../../api-model/enums/cost-curves-metric-format';
import { IMetricDto } from '../../api-model/metric-dto';
import { IMetricWithTargetDto } from '../../api-model/metric-with-target-dto';
import { IMetricWithTargetRangeDto } from '../../api-model/metric-with-target-range-dto';
import { INetworkDto } from '../../api-model/network-dto';
import { AuthService } from '../../shared/auth/auth.service';
import { MetricValuePipe } from '../../shared/pipes/metric-value.pipe';
import { OsIconPipe } from '../../shared/pipes/os-icon.pipe';
import { ShortCurrencyPipe } from '../../shared/pipes/short-currency.pipe';
import { ChartHelperService } from '../../shared/services/chart-helper.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { ThemeService } from '../../shared/services/theme.service';
import { AnalysisSettingsService } from './analysis-settings.service';
import { Perspective, PerspectiveSingularDesc } from './perspective';
import { IPerspectiveMetadata } from './perspective-metadata';
import { RecentTrendsPageSettingsService } from './recent-trends-page-settings.service';

interface ToplineTile {
  config: IAttributionMetadataMetricDto;
  analysis: IMetricDto;
  miniTrendChart?: Options;
}

interface HeadlineTile {
  config: IAttributionMetadataMetricDto;
  analysis: IMetricWithTargetRangeDto;
  miniTrendChart?: Options;
  gaugeChart?: Options;
}

interface TimeseriesTile {
  config: IAttributionMetadataMetricDto;
  analysis: IMetricWithTargetDto | IMetricWithTargetRangeDto;
  timeseriesChart?: Options;
}

@Component({
  selector: 'app-recent-trends',
  templateUrl: './recent-trends.component.html',
  styleUrls: ['./recent-trends.component.scss']
})
export class RecentTrendsComponent implements OnInit, OnDestroy {

  public perspectiveId: string;
  public Highcharts: typeof Highcharts = Highcharts;
  public perspective: Perspective;
  public perspectiveMetadata?: IPerspectiveMetadata;
  public analysis: IDrilldownDto;
  public costCurvesMetrics: ICostCurvesDto[];
  public costCurvesMetricNames: string[];
  public costCurvesChart?: Options;
  public network?: INetworkDto;
  public app: IAppDto;

  public toplineTiles: ToplineTile[] = [];
  public headlineTiles: HeadlineTile[] = [];
  public timeseriesTiles: TimeseriesTile[] = [];
  public timeseriesTileRows: TimeseriesTile[][] = [];

  private activeAdvertiserSubscription: Subscription;
  private routeDataSubscription?: Subscription;
  private pageSettingsSubscription?: Subscription;
  private themeSubscription: Subscription;
  private settingsSubscription: Subscription;
  private readonly analysisResolver: Resolve<IDrilldownDto>;
  private readonly costCurvesResolver: Resolve<ICostCurvesDto[]>;
  private showLastTouch = false;

  public constructor(
    public readonly title: Title,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly theme: ThemeService,
    private readonly analysisSettings: AnalysisSettingsService,
    private readonly chartHelper: ChartHelperService,
    private readonly metricValuePipe: MetricValuePipe,
    private readonly osIcon: OsIconPipe,
    private readonly shortCurrency: ShortCurrencyPipe,
    private readonly currencyPipe: CurrencyPipe,
    private readonly decimalPipe: DecimalPipe,
    private readonly localStorageService: LocalStorageService,
    private readonly pageSettingsService: RecentTrendsPageSettingsService
  ) {
    this.analysisResolver = inject(this.route.snapshot.routeConfig.resolve.analysis) as Resolve<IDrilldownDto>;
    if (this.route.snapshot.routeConfig.resolve.costCurves) {
      this.costCurvesResolver = inject(this.route.snapshot.routeConfig.resolve.costCurves) as Resolve<ICostCurvesDto[]>;
    }
    this.perspectiveId = route.snapshot.params.networkId || route.snapshot.params.regionId;
    this.title.setTitle(`${PerspectiveSingularDesc[this.perspective]} > Recent Trends`);
  }

  public get Perspective() { return Perspective; }

  public get iconUrls() { return [this.app?.iconUrlSmall, this.osIcon.transform(this.app?.operatingSystem), this.network?.iconUrlSmall, this.perspectiveMetadata?.iconUrlSmall, this.perspectiveMetadata?.iconUrl].filter(x => x); }

  public get titleBreadcrumbs() {
    const crumbs = [this.app?.displayName];
    if (this.network) { crumbs.push(this.network?.displayName); }
    crumbs.push(this.perspectiveMetadata?.displayName || this.analysis?.id, 'Recent Trends');
    return crumbs.filter(x => x);
  }

  public get costCurvesMetric() {
    if (!this.hasCostCurves) { return null; }
    const m = this.localStorageService.get<string>('costCurves.metric');
    return this.costCurvesMetrics.find(x => x.metric === m) || first(this.costCurvesMetrics) || null;
  }

  public get costCurvesMetricName() {
    if (!this.hasCostCurves) { return null; }
    const m = this.localStorageService.get<string>('costCurves.metric');
    return this.costCurvesMetrics.find(x => x.metric === m)?.metric || first(this.costCurvesMetrics)?.metric || '';
  }

  public set costCurvesMetricName(value: string) {
    if (!this.hasCostCurves) { return; }
    this.localStorageService.set('costCurves.metric', value);
  }

  public get showLastTouchData() { return this.showLastTouch || false; }

  public set showLastTouchData(value: boolean) { this.showLastTouch = value }

  public get showHistoricMax() { return this.localStorageService.get<boolean>('costCurves.showHistoricMax') || false; }

  public set showHistoricMax(value: boolean) { this.localStorageService.set('costCurves.showHistoricMax', value); }

  public get showOptimisingTowards() { return this.localStorageService.get<boolean>('costCurves.showOptimisingTowards') || false; }

  public set showOptimisingTowards(value: boolean) { this.localStorageService.set('costCurves.showOptimisingTowards', value); }

  public get showCompareTabs() { return this.perspective !== Perspective.Publishers && this.perspective !== Perspective.Campaigns; }

  public get showNetworkDrilldownTabs() { return this.perspective === Perspective.Networks || this.perspective === Perspective.Publishers || this.perspective === Perspective.Campaigns; }

  public get showRegionFilter() { return this.perspective !== Perspective.Regions; }

  public get showNetworkFilter() { return this.perspective === Perspective.Regions; }

  public get showOrganics() { return this.perspective === Perspective.Regions && !this.analysisSettings.networkId; }

  public get hasCostCurves() { return this.perspective === Perspective.Networks; }

  public get helpArticleId() {
    switch (this.perspective) {
      case Perspective.Networks:
        return '4756178429081';
      case Perspective.Regions:
        return '4756307990425';
      case Perspective.Publishers:
        return '24980748742041';
      case Perspective.Campaigns:
        return '24980728785689';
    }
  }

  public ngOnInit(): void {
    this.activeAdvertiserSubscription = this.auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(async () => {
      // When the user's active advertiser changes, redirect back to the dashboard
      this.router.navigate(['/']);
    });

    this.pageSettingsSubscription = this.pageSettingsService.settings$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(async () => this.loadData(true));

    this.routeDataSubscription = this.route.data.subscribe(async d => {
      this.app = d.app;
      this.analysis = d.analysis;
      this.perspectiveId = this.route.snapshot.params.networkId || this.route.snapshot.params.regionId;
      this.perspective = d.perspective;
      this.perspectiveMetadata = (this.route.snapshot.data.perspectiveMetadata as IPerspectiveMetadata[])?.find(x => x.id === this.perspectiveId);
      this.network = d.network;
      if (this.hasCostCurves) {
        this.costCurvesMetrics = d.costCurves;
        this.costCurvesMetricNames = uniq(this.costCurvesMetrics.map(x => x.metric)).sort();
      }
      this.title.setTitle(`${this.perspectiveMetadata?.displayName || PerspectiveSingularDesc[this.perspective]} > Recent Trends`);

      this.settingsSubscription?.unsubscribe();
      this.analysisSettings.settings$.pipe(skip(1), distinctUntilChanged((x, y) =>
        isEqual(x.regionId, y.regionId) &&
        isEqual(x.networkId, y.networkId) &&
        isEqual(x.cadence, y.cadence) &&
        isEqual(x.smoothingPeriod, y.smoothingPeriod) &&
        isEqual(x.dateRange, y.dateRange)))
        .subscribe(async () => this.loadData(true));

      this.loadData();
    });

    this.themeSubscription = this.theme.changed$.subscribe(_ => this.configureCharts());
  }

  public ngOnDestroy(): void {
    this.activeAdvertiserSubscription?.unsubscribe();
    this.pageSettingsSubscription?.unsubscribe();
    this.routeDataSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
    this.settingsSubscription?.unsubscribe();
  }

  public async loadData(reloadAnalysis = false) {
    if (reloadAnalysis) {
      this.analysis = await this.analysisResolver.resolve(this.route.snapshot, this.router.routerState.snapshot) as IDrilldownDto;
    }
    if (this.costCurvesResolver) {
      this.costCurvesMetrics = await this.costCurvesResolver.resolve(this.route.snapshot, this.router.routerState.snapshot) as ICostCurvesDto[];
      this.costCurvesMetricNames = uniq(this.costCurvesMetrics.map(x => x.metric)).sort();
    }
    this.composeTilesAndConfigureCharts();
  }

  public cadenceTrendLabel(chart: Options) {
    const trendLength = (chart?.series[0] as SeriesSplineOptions)?.data?.length || 0;
    switch (this.analysisSettings.cadence) {
      case Cadence.Weekly:
        return `${trendLength} week`;
      case Cadence.Monthly:
        return `${trendLength} month`;
      default:
        return `${trendLength} day`;
    }
  }

  public configureCostCurvesChart() {
    if (!this.costCurvesMetric) {
      this.costCurvesChart = undefined;
      return;
    }

    const self = this;

    this.costCurvesChart = merge(this.chartHelper.splineChart(), {
      chart: { height: 500 },
      xAxis: {
        visible: true,
        title: {
          text: '<div class="chart-floating-axis-title">Spend</div>',
          offset: -20,
          useHTML: true
        },
        tickPixelInterval: 100,
        labels: {
          formatter() { return self.shortCurrency.transform(this.value); }
        },
        plotLines: [{
          value: this.analysis?.metrics.find(x => x.config.name === 'spend')?.current,
          width: 2,
          zIndex: 5000,
          className: this.showOptimisingTowards ? '' : 'hidden',
          color: this.chartHelper.plotLineLabelColour,
          label: {
            text: '<span class="chart-marker-line-label">Current</span>',
            useHTML: true
          }
        }, !this.showHistoricMax ? {} : {
          value: this.costCurvesMetric.historicMaximumSpend,
          width: 2,
          zIndex: 5000,
          className: this.showOptimisingTowards ? '' : 'hidden',
          color: this.chartHelper.plotLineLabelColour,
          label: {
            text: `<span class="chart-marker-line-label">Historic max${!this.costCurvesMetric.historicMaximumSpendDate ? '' : ` ${moment.utc(this.costCurvesMetric.historicMaximumSpendDate)?.format('MMM \'YY')}`}</span>`,
            useHTML: true
          }
        }, !this.showOptimisingTowards ? {} : {
          value: this.costCurvesMetric.optimumSpend,
          width: 2,
          zIndex: 5000,
          className: this.showOptimisingTowards ? '' : 'hidden',
          color: this.theme.dark ? '#609164' : '#015408',
          label: {
            text: `<span class="chart-marker-line-label" style="color: ${this.theme.dark ? '#609164' : '#015408'}">Optimising towards</span>`,
            useHTML: true
          }
        }].filter(x => x.value)
      },
      yAxis: {
        visible: true,
        title: {
          text: `<div class="chart-floating-axis-title y-axis">${this.costCurvesMetric.metric}</div>`,
          rotation: 90,
          offset: -15,
          useHTML: true
        }
      },
      tooltip: this.chartHelper.tooltip(function () {
        switch (self.costCurvesMetric?.metricFormat || CostCurvesMetricFormat.Number) {
          case CostCurvesMetricFormat.Currency:
            return self.currencyPipe.transform(this.y, 'USD', 'symbol', '1.0-0');
          default:
            return self.decimalPipe.transform(this.y, '1.0-0');
        }
      }),
      series: ([{
        name: 'AIM Cost Curve',
        data: this.costCurvesMetric.recent?.map(x => ({ name: `${this.costCurvesMetric.metric} at ${self.shortCurrency.transform(x.spend)} spend`, x: x.spend, y: x.value })) || [],
        color: this.theme.dark ? '#63c96c' : '#007a0b'
      }, {
        name: 'Historic',
        data: this.costCurvesMetric.historic?.map(x => ({ name: `${this.costCurvesMetric.metric} at ${self.shortCurrency.transform(x.spend)} spend`, x: x.spend, y: x.value })) || [],
        visible: false,
        color: this.theme.dark ? '#38afff' : '#2984c2'
      }, {
        name: 'Last Touch Cost Curve',
        data: this.costCurvesMetric.lastTouch?.trend?.map(x => ({ name: `${this.costCurvesMetric.metric} at ${self.shortCurrency.transform(x.spend)} spend`, x: x.spend, y: x.value })) || [],
        color: this.theme.dark ? '#db4d5a' : '#cc182a',
        visible: this.showLastTouchData
      }, {
        name: 'AIM Cost Curve (1 std dev)',
        type: 'areasplinerange',
        fillOpacity: 1,
        opacity: 0.1,
        showInTooltip: false,
        data: this.costCurvesMetric.recent.map(x => [x.spend, x.bandLower, x.bandUpper]),
        color: this.theme.dark ? '#63c96c' : '#007a0b'
      }, {
        name: 'Historic (1 std dev)',
        type: 'areasplinerange',
        fillOpacity: 1,
        opacity: 0.1,
        visible: false,
        showInTooltip: false,
        data: this.costCurvesMetric.historic.map(x => [x.spend, x.bandLower, x.bandUpper]),
        color: this.theme.dark ? '#38afff' : '#2984c2'
      }, {
        name: 'Last Touch Cost Curve (1 std dev)',
        type: 'areasplinerange',
        fillOpacity: 1,
        opacity: 0.1,
        showInTooltip: false,
        data: this.costCurvesMetric.lastTouch.trend.map(x => [x.spend, x.bandLower, x.bandUpper]),
        color: this.theme.dark ? '#db4d5a' : '#cc182a',
        visible: this.showLastTouchData
      }, {
        name: 'Last Touch (scatter)',
        type: 'scatter',
        fillOpacity: 0.5,
        opacity: 0.5,
        showInTooltip: false,
        data: this.costCurvesMetric.lastTouch.points.map(x => [x.spend, x.value]),
        color: this.theme.dark ? '#db4d5a' : '#cc182a',
        enableMouseTracking: false,
        visible: this.showLastTouchData
      }] as (SeriesSplineOptions | (SeriesAreasplinerangeOptions & {
        showInTooltip?: boolean
      }) | SeriesScatterOptions)[]).filter(x => x.data.length)
    } as Options);
    delete (this.costCurvesChart.xAxis as XAxisOptions).categories;
    console.log(this.costCurvesChart);
  }

  private async composeTilesAndConfigureCharts() {
    const settings = await this.pageSettingsService.get();

    const availableToplineMetrics = await this.pageSettingsService.getAvailableToplineMetrics();
    const availableHeadlineMetrics = await this.pageSettingsService.getAvailableHeadlineMetrics();
    const availableTimeseriesChartMetrics = await this.pageSettingsService.getAvailableTimeseriesChartMetrics();

    this.toplineTiles = settings.toplineMetrics.filter(x => !x.startsWith('organic') || this.showOrganics).map(x => {
      return {
        config: availableToplineMetrics.find(m => m.name === x),
        analysis: this.analysis.metrics.find(m => m.config.name === x)
      };
    });

    this.headlineTiles = settings.headlineMetrics.filter(x => !x.startsWith('organic') || this.showOrganics).map(x => {
      return {
        config: availableHeadlineMetrics.find(m => m.name === x),
        analysis: this.analysis.metrics.find(m => m.config.name === x) as IMetricWithTargetRangeDto
      };
    });

    this.timeseriesTiles = settings.timeseriesChartMetrics.filter(x => !x.startsWith('organic') || this.showOrganics).map(x => ({
      config: availableTimeseriesChartMetrics.find(m => m.name === x),
      analysis: this.analysis.metrics.find(m => m.config.name === x)
    }));

    this.timeseriesTileRows = chunk(this.timeseriesTiles, 2);

    this.configureCharts();
  }

  private configureCharts() {
    if (!this.analysis) { return; }
    this.configureMiniTrendLineCharts();
    this.configureGaugeCharts();
    this.configureTimeseriesCharts();
    this.configureCostCurvesChart();
  }

  private configureMiniTrendLineCharts() {
    let mostRecent: Date;
    switch (this.analysisSettings.cadence) {
      case Cadence.Weekly:
        mostRecent = this.analysisSettings.dateRange.end.startOf('isoWeek').toDate();
        break;
      case Cadence.Monthly:
        mostRecent = this.analysisSettings.dateRange.end.startOf('month').toDate();
        break;
      default:
        mostRecent = this.analysisSettings.dateRange.end.startOf('day').toDate();
        break;
    }
    for (let tile of flatMap([this.toplineTiles, this.headlineTiles])) {
      tile.miniTrendChart = this.chartHelper.miniTrendLine({ metric: tile.config, data: tile.analysis.trend.filter(x => x.date <= mostRecent && x.hasAttributionData) });
    }
  }

  private configureGaugeCharts() {
    for (let tile of this.headlineTiles) {
      const analysis = cloneDeep(tile.analysis);
      const buffer = ((analysis.targetMax - analysis.targetMin) * 0.1);
      // Extend the range to include the actual value if the actual value is "better" than the target range
      if (analysis.current + buffer > analysis.targetMax && !tile.config.negativeIsGood) { analysis.targetMax = analysis.current + buffer; }
      if (analysis.current - buffer < analysis.targetMin && tile.config.negativeIsGood) { analysis.targetMin = analysis.current - buffer; }
      tile.gaugeChart = this.chartHelper.gaugeChart({
        actual: analysis.current,
        targetMin: analysis.targetMin,
        targetMax: analysis.targetMax,
        axisLabelFormatter: x => this.metricValuePipe.transform(x, tile.config, { decimals: 0, prefix: '', suffix: '' }),
        underTargetIsGood: tile.config.negativeIsGood
      });
    }
  }

  private configureTimeseriesCharts() {
    // tslint:disable-next-line: no-this-assignment
    const self = this;
    for (let tile of this.timeseriesTiles) {
      tile.timeseriesChart = this.chartHelper.recentTrendsTimeseriesChart({
        metricConfig: tile.config,
        data: tile.analysis.trend,
        name: tile.config.displayName,
        // tslint:disable-next-line:only-arrow-functions
        tooltipValueFormatter() { return self.metricValuePipe.transform(this.y || 0, tile.config, { expand: true }); },
        tooltipRangeValueFormatter() {
          return `${tile.config.displayPrefix || ''}${self.metricValuePipe.transform(this.low || 0, tile.config, { prefix: '', suffix: '', expand: true })}`
            + ` to ${self.metricValuePipe.transform(this.high || 0, tile.config, { prefix: '', suffix: '', expand: true })}${tile.config.displaySuffix || ''}`;
        }
      });
    }
  }

}
