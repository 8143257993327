import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[firstCell]',
  templateUrl: './first-cell.component.html',
  styleUrls: ['./first-cell.component.scss']
})
export class FirstCellComponent {

  @Input() public label: string;

  @Input() public icon: string;

  @Input() public iconClass: string;

  @Input() public copyable = true;

}
