import { Component, ContentChild, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { IHistoryDto } from '../../api-model/history-dto';
import { BladeComponent } from './blade.component';

@Component({
  selector: 'app-history-blade',
  templateUrl: './history-blade.component.html',
  styleUrls: ['./history-blade.component.scss']
})
export class HistoryBladeComponent {

  @ViewChild(BladeComponent, { static: false }) public blade: BladeComponent;

  @ContentChild('historyModelTemplate') public historyModelTemplate: TemplateRef<ElementRef>;

  @Input() public title: string;

  @Input() public subTitle: string;

  @Input() public history: IHistoryDto[];

  public open() { this.blade?.open(); }

  public close() { this.blade?.close(); }

}
