import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { flatMap, groupBy, keyBy, last, sum, uniq } from 'lodash-es';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { IAttributionMetricsResultDto } from '../../api-model/attribution-metrics-response-dto';
import { IComparisonDto, IComparisonRowDto } from '../../api-model/comparison-dto';
import { IDrilldownDto } from '../../api-model/drilldown-dto';
import { AttributionQueryHelperService } from '../../shared/services/attribution-query-helper.service';
import { AppsAnalysisMetadataService } from '../apps/apps-analysis-metadata.service';
import { AnalysisSettingsService } from './analysis-settings.service';
import { ComparePageSettingsService } from './compare-page-settings.service';
import { Cadence } from '../../api-model/enums/cadence';
import { ExportOptions } from 'src/app/api-model/enums/export-options';
import { environment } from 'src/environments/environment';
import { IAppMetricsDto } from 'src/app/api-model/app-metrics-dto';
import { IAttributionMetadataAppDto } from 'src/app/api-model/attribution-metadata-app-dto';

interface ComparisonMetricsExportOption {
  appId: string;
  sortField: string;
  sortDirection?: string;
  metricNames: string[];
  exportOption: ExportOptions;
  networkId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalysisDataService {

  public pageSize = 50;

  public constructor(
    private readonly http: HttpClient,
    private readonly appsAnalysisMetadataService: AppsAnalysisMetadataService,
    private readonly queryHelper: AttributionQueryHelperService,
    private readonly analysisSettings: AnalysisSettingsService,
    private readonly pageSettingsService: ComparePageSettingsService
  ) { }

  public async getComparisonMetrics(options: {
    appId: string;
    idField: keyof IAttributionMetricsResultDto;
    sortField: string;
    sortDirection?: string;
    useRegionFilter?: boolean;
    useNetworkFilter?: boolean;
    networkId?: string;
    metricNames: string[];
    pageNumber?: number;
  }): Promise<IComparisonDto> {
    const app = await this.appsAnalysisMetadataService.getById(options.appId);
    if (!app) { return { rows: [], pageNumber: 1, availablePages: 1, availableRows: 0 }; }

    const metrics = app.metrics.filter(x => options.metricNames.includes(x.name) || x.name === 'spend');

    const cadenceOptions = this.queryHelper.getQueryOptionsForCadence(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    const sort = `, sort: [{field: ${options.sortField} direction: ${options.sortDirection || 'DESC'}}, {field: DATE}]`;

    const paginationFilters = [
      options.useRegionFilter && this.analysisSettings.regionId ? `, regionIds: ["${this.analysisSettings.regionId}"]` : '',
      options.networkId
        ? `, networkIds: ["${options.networkId}"]`
        : options.useNetworkFilter && this.analysisSettings.networkId ? `, networkIds: ["${this.analysisSettings.networkId}"]` : ''
    ].join();

    const pagination = `skip: ${((options.pageNumber || 1) - 1) * this.pageSize}, take: ${this.pageSize}`;
    const paginationResult = await firstValueFrom(this.http.post<any>(app.endpoint, {
      query: `query {
        attribution(options: {${cadenceOptions.recentPastAndNextPeriodDates}${paginationFilters}${sort}${pagination}}) {
          results { ${options.idField} ${metrics.map(x => x.name).join(' ')} }
          availableResultsCount
        }
      }`
    }));

    const idFieldsForPage = (paginationResult?.data?.attribution.results || []).map((x: any) => x[options.idField] as string);
    const availableIds = paginationResult?.data?.attribution.availableResultsCount as number || 0;

    let filters = `${options.idField}s: ["${idFieldsForPage.join('", "')}"]`;
    if (options.idField !== 'regionId' && options.useRegionFilter && this.analysisSettings.regionId) { filters += `, regionIds: ["${this.analysisSettings.regionId}"]`; }
    if (options.idField !== 'networkId') {
      filters += options.networkId
        ? `, networkIds: ["${options.networkId}"]`
        : options.useNetworkFilter && this.analysisSettings.networkId ? `, networkIds: ["${this.analysisSettings.networkId}"]` : '';
    }

    const result = await firstValueFrom(this.http.post<any>(app.endpoint, {
      query: `query {
        attribution(options: {${cadenceOptions.recentPastAndNextPeriodDates}${filters}${sort}${cadenceOptions.periodicity}}) {
          results { ${options.idField} date ${metrics.map(x => `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`).join(' ')} }
        }
        potential(options: {${cadenceOptions.nextPeriodDates}${filters}${sort}${cadenceOptions.periodicity}}) {
          results { ${options.idField} spend }
        }
      }`
    }));

    const today = moment.utc().startOf('day').toDate();
    let mostRecent: Date;
    switch (this.analysisSettings.cadence) {
      case Cadence.Weekly:
        mostRecent = this.analysisSettings.dateRange.end.startOf('isoWeek').toDate();
        break;
      case Cadence.Monthly:
        mostRecent = this.analysisSettings.dateRange.end.startOf('month').toDate();
        break;
      default:
        mostRecent = this.analysisSettings.dateRange.end.startOf('day').toDate();
        break;
    }

    await this.addAttributionForMostRecentPeriod(result?.data?.attribution.results as IAttributionMetricsResultDto[], mostRecent);

    const attributionById = groupBy((result?.data?.attribution.results as IAttributionMetricsResultDto[]).filter(x => x.date.setHours(0, 0, 0) <= mostRecent.setHours(0, 0, 0)), x => x[options.idField]);
    const attributionNextPeriodById = keyBy((result?.data?.attribution.results as IAttributionMetricsResultDto[]).filter(x => x.date.setHours(0, 0, 0) > today.setHours(0, 0, 0)), x => x[options.idField]);
    const potentialById = keyBy(result?.data?.potential.results as IAttributionMetricsResultDto[], x => x[options.idField]);
    const totalSpend = sum(Object.values(attributionById).map(x => last(x)?.spend || 0));
    const benefitMetric = (await this.pageSettingsService.get())?.benefitMetric;
    const totalBenefit = sum(Object.values(attributionById).map(x => (x.length ? last(x)[benefitMetric] : 0) || 0));

    return {
      rows: Object.entries(attributionById).map(([id, attribution]) => {
        const recommendedNextPeriod = potentialById[id];
        const bauNextPeriod = attributionNextPeriodById[id];
        const attributionEnd = last(attribution);
        const mappedMetrics = metrics.map(x => this.queryHelper.mapMetric(attribution, x, this.analysisSettings.cadence, this.analysisSettings.dateRange));
        return ({
          id: id,
          metrics: mappedMetrics,
          bauSpendNextPeriod: bauNextPeriod?.spend || 0,
          bauSpendNextPeriodConfig: mappedMetrics.find(m => m.config.name === "spend")?.config,
          recommendedSpendNextPeriod: recommendedNextPeriod?.spend || 0,
          recommendedSpendNextPeriodPercentageChange: recommendedNextPeriod?.spend && bauNextPeriod?.spend ? 0 - ((bauNextPeriod.spend - recommendedNextPeriod.spend) / bauNextPeriod.spend) : 0,
          shareOfSpend: attributionEnd && totalSpend ? (attributionEnd.spend || 0) / totalSpend : 0,
          shareOfBenefit: attributionEnd && totalBenefit ? (attributionEnd[benefitMetric] || 0) / totalBenefit : 0
        } as IComparisonRowDto);
      }),
      pageNumber: options.pageNumber || 1,
      availablePages: Math.ceil(availableIds / this.pageSize),
      availableRows: availableIds
    };
  }

  public async getUnpaidSourcesComparisonMetrics(appId: string, metricNames: string[]) {
    return this.getComparisonMetrics({
      appId: appId,
      networkId: environment.organicNetworkId,
      idField: 'publisherName',
      sortField: 'PUBLISHER_NAME',
      useRegionFilter: true,
      metricNames: metricNames
    });
  }

  public async getComparisonMetricsForExport(options: ComparisonMetricsExportOption) {
    const app = await this.appsAnalysisMetadataService.getById(options.appId);
    if (!app) { return {}; }
    const cadenceOptions = this.queryHelper.getQueryOptionsForCadence(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    
    const { filters, sort, metricsPartQuery } = this.getGqlQueryElements(options, app);

    const result = await firstValueFrom(this.http.post<any>(app.endpoint, {
      query: `query {
        attribution(options: {${cadenceOptions.recentPastAndNextPeriodDates}${filters}${sort}${cadenceOptions.periodicity}}) {
          results { networkId date ${this.getNameFields(options)} ${metricsPartQuery} }
        }
        potential(options: {${cadenceOptions.nextPeriodDates}${sort}${cadenceOptions.periodicity}}) {
          results { networkId spend }
        }
        byOrganicPublisher: attribution(options: {${cadenceOptions.recentPastAndNextPeriodDates}${cadenceOptions.periodicity},networkIds: ["${environment.organicNetworkId}"]}) {
            results { 
            date publisherName ${metricsPartQuery}
            }
        }
      }`
    }));

    return this.getCombinedResults(result);
  }

  private getGqlQueryElements(options: ComparisonMetricsExportOption, app: IAttributionMetadataAppDto) {
    const sort = `, sort: [{field: ${options.sortField} direction: ${options.sortDirection || 'DESC'}}, {field: DATE}]`;
    const filters = options.networkId ? `, networkIds: ["${options.networkId}"]` : "";
    const metrics = app.metrics.filter(x => options.metricNames.includes(x.name) || x.name === 'spend');
    const metricsPartQuery = metrics.map(x => `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`).join(' ');
    return { filters, sort, metricsPartQuery };
  }

  private getNameFields(options: ComparisonMetricsExportOption) {
    switch (options.exportOption) {
      case ExportOptions.ByNetworkPublisherCampaign:
        return "networkName publisherName campaignName";
      case ExportOptions.ByNetworkPublisher:
        return "networkName publisherName";
      default:
        return "networkName";
    }
  }

  private getCombinedResults(result: any) {
    let organicPublisherResult = [...result?.data?.byOrganicPublisher.results];
    /* rename publisherName field to networkName */
    organicPublisherResult.forEach(item => {
      item.networkName = item.publisherName;
      delete item.publisherName;
    });
    const preResults = [
      ...result?.data?.attribution.results,
      ...organicPublisherResult
    ]
    const combinedResults = groupBy(
      preResults as IAttributionMetricsResultDto[],
      (x) => x.date.toISOString()
    ) as {
      [date: string]: IAttributionMetricsResultDto[];
    };
    return combinedResults;
  }

  public async getMetricsByAppId(appId: string, metricNames: string[]): Promise<IAppMetricsDto> {
    const app = await this.appsAnalysisMetadataService.getById(appId);
    if (!app) { return null; }

    metricNames = uniq(
      [
        ...metricNames, 
        'globalcpi', 
        'globalcpa',
        'globalroas', 
        'paidInstalls', 
        'paidFirstTimeConversions', 
        'paidRevenue365d', 
        'paidRevenue7d',
        'paidFirstTimeConversions365d', 
        'paidPayments365d'
      ]
    );

    const metrics = app.metrics.filter(x => metricNames.includes(x.name) || x.name === 'spend');

    const cadenceOptions = this.queryHelper.getQueryOptionsForCadence(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    const result = await firstValueFrom(this.http.post<any>(app.endpoint, {
      query: `query {
        attribution(options: {${cadenceOptions.recentPastAndNearFutureDates}${cadenceOptions.periodicity}}) {
          results { date ${metrics.map(x => `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`).join(' ')}}
        }
        potential(options: {${cadenceOptions.recentPastAndNearFutureDates}${cadenceOptions.periodicity}}) {
          results { date ${flatMap(metrics.map(x => x.ranged
        ? [
          `${x.name}Min(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`,
          `${x.name}Max(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`
        ]
        : `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`)).join(' ')}}
        }
      }`
    }));

    const attribution = keyBy(result?.data?.attribution.results as IAttributionMetricsResultDto[], x => x.date.toISOString()) as {
      [date: string]: IAttributionMetricsResultDto
    };
    const potential = keyBy(result?.data?.potential.results as IAttributionMetricsResultDto[], x => x.date.toISOString()) as {
      [date: string]: IAttributionMetricsResultDto
    };
    const timeseries = uniq(Object.keys(attribution).concat(Object.keys(potential))).sort().map(date => ({
      date: attribution[date]?.date || potential[date]?.date,
      attribution: attribution[date],
      potential: potential[date]
    }));

    return {
      appId,
      metrics: metrics.map(metric => metric.ranged
        ? this.queryHelper.mapMetricWithTargetRange(timeseries, metric, this.analysisSettings.cadence, this.analysisSettings.dateRange)
        : this.queryHelper.mapMetricWithTarget(timeseries, metric, this.analysisSettings.cadence, this.analysisSettings.dateRange))
    };
  }

  private async addAttributionForMostRecentPeriod(results: IAttributionMetricsResultDto[], mostRecent: Date) {
    const lastPeriodEntries = []
    const nwIds = results.map(r => r.networkId).filter((v, i, a) => a.indexOf(v) == i);

    nwIds.forEach(nwid => {
      const attr = results.find(r => r.networkId === nwid && new Date(r.date).setHours(0, 0, 0) === mostRecent.setHours(0, 0, 0));
      if (!attr) {
        lastPeriodEntries.push({
          networkId: nwid,
          date: mostRecent,
          cpa: 0,
          spend: 0,
          paidRevenue365d: 0,
          cpi: 0,
          roas: 0
        })
      }
    });
    lastPeriodEntries.forEach(e => {
      results.push(e)
    });
  }


  public async getDrilldownMetricsById(options: {
    appId: string;
    id: string;
    idFilterField: string;
    useRegionFilter?: boolean;
    useNetworkFilter?: boolean;
    networkId?: string;
    metricNames: string[];
  }): Promise<IDrilldownDto> {
    const app = await this.appsAnalysisMetadataService.getById(options.appId);
    if (!app) { return null; }

    const metrics = app.metrics.filter(x => options.metricNames.includes(x.name) || x.name === 'spend');

    const cadenceOptions = this.queryHelper.getQueryOptionsForCadence(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    const filters = [
      `, ${options.idFilterField}: ["${options.id}"]`,
      options.useRegionFilter && this.analysisSettings.regionId ? `, regionIds: ["${this.analysisSettings.regionId}"]` : '',
      options.networkId
        ? `, networkIds: ["${options.networkId}"]`
        : options.useNetworkFilter && this.analysisSettings.networkId ? `, networkIds: ["${this.analysisSettings.networkId}"]` : ''
    ].join('');
    const result = await firstValueFrom(this.http.post<any>(app.endpoint, {
      query: `query {
        attribution(options: {${cadenceOptions.recentPastAndNearFutureDates}${filters}${cadenceOptions.periodicity}}) {
          results { date ${metrics.map(x => `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`).join(' ')} }
        }
        potential(options: {${cadenceOptions.recentPastAndNearFutureDates}${filters}${cadenceOptions.periodicity}}) {
          results { date ${flatMap(app.metrics.map(x => x.ranged
        ? [
          `${x.name}Min(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`,
          `${x.name}Max(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`
        ]
        : `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`)).join(' ')} }
        }
      }`
    }));

    const attribution = keyBy(result?.data?.attribution.results as IAttributionMetricsResultDto[], x => x.date.toISOString()) as {
      [date: string]: IAttributionMetricsResultDto
    };
    const potential = keyBy(result?.data?.potential.results as IAttributionMetricsResultDto[], x => x.date.toISOString()) as {
      [date: string]: IAttributionMetricsResultDto
    };
    const timeseries = uniq(Object.keys(attribution).concat(Object.keys(potential))).sort().map(date => ({
      date: attribution[date]?.date || potential[date]?.date,
      attribution: attribution[date],
      potential: potential[date]
    }));

    return {
      id: options.id,
      metrics: metrics.map(metric => metric.ranged
        ? this.queryHelper.mapMetricWithTargetRange(timeseries, metric, this.analysisSettings.cadence, this.analysisSettings.dateRange)
        : this.queryHelper.mapMetricWithTarget(timeseries, metric, this.analysisSettings.cadence, this.analysisSettings.dateRange))
    };
  }

}
