import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import 'daterangepicker';
import daterangepicker from 'daterangepicker';
import $ from 'jquery';
import moment, { Moment } from 'moment';
import { FormattedDatePipe } from './pipes/formatted-date.pipe';

// noinspection TypeScriptUMDGlobal
@Component({
  selector: 'app-date-range-text-box',
  templateUrl: './date-range-text-box.component.html',
  styleUrls: ['./date-range-text-box.component.scss']
})
export class DateRangeTextBoxComponent implements OnInit {

  @Input() public disabled = false;

  // Generate a random ID so that we can reference it from jQuery
  public id = Math.random().toString(36).substring(2);
  @Output() public readonly changed = new EventEmitter<{
    start: Moment,
    end: Moment
  }>();
  @Output() public startDateChange = new EventEmitter<Moment>();
  @Output() public endDateChange = new EventEmitter<Moment>();
  private _picker: JQuery<HTMLElement>;

  public constructor(private readonly formattedDate: FormattedDatePipe) {}

  private _minDate: Moment;
  @Input()
  public get minDate() { return this._minDate; }

  public set minDate(value: Moment) {
    if (this._minDate?.valueOf() === value?.valueOf()) { return; }
    this._minDate = value;
    if (this._picker) { this.updatePicker(); }
  }

  private _maxDate = moment.utc();
  @Input()
  public get maxDate() { return this._maxDate; }

  public set maxDate(value: Moment) {
    if (this._maxDate?.valueOf() === value?.valueOf()) { return; }
    this._maxDate = value;
    if (this._picker) { this.updatePicker(); }
  }

  private _opens: 'left' | 'right' | 'center';
  @Input()
  public get opens() { return this._opens; }

  public set opens(value: 'left' | 'right' | 'center') {
    if (this._opens === value) { return; }
    this._opens = value;
    if (this._picker) { this.updatePicker(); }
  }

  private _startDate: Moment;
  @Input()
  public get startDate() { return this._startDate; }

  public set startDate(value: Moment) {
    if (this._startDate?.valueOf() === value?.valueOf()) { return; }
    this._startDate = value;
    this._picker?.data('daterangepicker')?.setStartDate(this._startDate);
  }

  private _endDate: Moment;
  @Input()
  public get endDate() { return this._endDate; }

  public set endDate(value: Moment) {
    if (this._endDate?.valueOf() === value?.valueOf()) { return; }
    this._endDate = value;
    this._picker?.data('daterangepicker')?.setEndDate(this._endDate);
  }

  public get description() {
    return `${this.formattedDate.transform(this.startDate, 'm', null, 'always')} to ${this.formattedDate.transform(this.endDate, 'm', null, 'always')}`;
  }

  public checkDisabled(event: MouseEvent) {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
      return false;
    }
    return true;
  }

  public ngOnInit() {
    setTimeout(() => this.updatePicker(), 0);
  }

  private updatePicker() {
    this._picker = $(`#${this.id}`).daterangepicker({
      showDropdowns: true,
      alwaysShowCalendars: true,
      startDate: this.startDate,
      endDate: this.endDate,
      minDate: this.minDate,
      maxDate: this.maxDate,
      opens: this.opens,
      locale: { format: 'D MMM YYYY' }
    } as daterangepicker.Options, (selectedStart: Moment, selectedEnd: Moment) => {
      // Convert to UTC because daterangepicker isn't timezone aware
      this.startDate = moment.utc(moment.min(selectedStart, selectedEnd).format('YYYY-MM-DDTHH:mm:ss') + 'Z').startOf('day');
      this.endDate = moment.utc(moment.max(selectedStart, selectedEnd).format('YYYY-MM-DDTHH:mm:ss') + 'Z').endOf('day');
      this.changed.emit({ start: this.startDate, end: this.endDate });
      this.startDateChange.emit(this.startDate);
      this.endDateChange.emit(this.endDate);
    });
  }

}
