import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Perspective } from './perspective';

@Component({
  selector: 'app-drilldown-tabs',
  templateUrl: './drilldown-tabs.component.html',
  styleUrls: ['./drilldown-tabs.component.scss']
})
export class DrilldownTabsComponent {

  public constructor(private readonly route: ActivatedRoute) {

  }

  public get showSpendAdvice() {
    return this.route.snapshot.data.perspective === Perspective.Networks;
  }

}
