<a (click)="show()"><i class="fa-solid fa-gear page-settings"></i></a>

<ng-template #pageSettings>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Page Settings</h4>
    <button (click)="modal.hide()" aria-label="Close" class="close float-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body no-padding">
    <div class="columns">
      <div class="headline metrics picklist single-column">
        <h4>Metric Columns</h4>
        <ul>
          <li (click)="toggleColumnMetric(metric.name)" *ngFor="let metric of availableColumnMetrics" [class.selected]="form.value.columnMetrics.includes(metric.name)">
            <i [class.fa-check-square]="form.value.columnMetrics.includes(metric.name)" [class.fa-square]="!form.value.columnMetrics.includes(metric.name)" class="fas"></i>
            <span class="name">{{metric.displayName}}</span>
            <span class="gap"></span>
            <i *ngIf="metric.name==='spend'" class="fa fa-thumb-tack pin"></i>
            <a (click)="moveMetricToTop(metric.name, $event)" *ngIf="metric.name!=='spend' && form.value.columnMetrics.includes(metric.name)">
              <i *ngIf="metric.name!=='spend'" class="fa-regular fa-circle-up"></i>
            </a>
          </li>
        </ul>
        <app-form-validation-messages [model]="form.controls.columnMetrics"></app-form-validation-messages>
      </div>
      <div class="headline metrics picklist single-column">
        <h4>Compare Share of Spend With</h4>
        <ul>
          <li (click)="setBenefitMetric(metric.name)" *ngFor="let metric of availableBenefitMetrics" [class.selected]="form.value.benefitMetric === metric.name">
            <i [class.fa-check-square]="form.value.benefitMetric === metric.name" [class.fa-square]="form.value.benefitMetric !== metric.name" class="fas"></i>
            <span class="name">{{metric.displayName}}</span>
          </li>
        </ul>
        <app-form-validation-messages [model]="form.controls.benefitMetric"></app-form-validation-messages>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-footer-content">
      <div class="left">
        <button (click)="submit()" [disabled]="!form.valid || saving" class="btn btn-primary">Save</button>
        <button (click)="modal.hide()" class="btn btn-secondary">Cancel</button>
      </div>
      <div class="right">
        <button (click)="reset()" class="btn btn-secondary">Reset to Default</button>
      </div>
    </div>
  </div>
</ng-template>
