import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEqual } from 'lodash-es';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { INetworkDto } from '../../api-model/network-dto';
import { IRegionDto } from '../../api-model/region-dto';
import { AppNetworksDataService } from '../../app-networks/app-networks-data.service';
import { RegionsDataService } from '../../regions/regions-data.service';
import { AnalysisSettingsService } from './analysis-settings.service';
import { Perspective } from './perspective';

@Component({
  selector: 'app-filtered-by',
  templateUrl: './filtered-by.component.html',
  styleUrls: ['./filtered-by.component.scss']
})
export class FilteredByComponent implements OnInit, OnDestroy {

  public region: IRegionDto;
  public network: INetworkDto;
  public appId: string;
  public perspective?: Perspective;
  private routeParamsSubscription: Subscription;
  private routeDataSubscription?: Subscription;
  private settingsSubscription: Subscription;

  public constructor(
    private readonly route: ActivatedRoute,
    private readonly analysisSettings: AnalysisSettingsService,
    private readonly regionsDataService: RegionsDataService,
    private readonly appNetworksDataService: AppNetworksDataService
  ) { }

  public get showRegionFilter() { return this.perspective !== Perspective.Regions; }

  public get showNetworkFilter() { return this.perspective === Perspective.Regions; }

  public ngOnInit(): void {
    this.routeParamsSubscription = this.route.params.subscribe(p => this.appId = p.appId);
    this.routeDataSubscription = this.route.data.subscribe(d => this.perspective = d.perspective);

    this.analysisSettings.settings$.pipe(skip(1), distinctUntilChanged((x, y) => isEqual(x.regionId, y.regionId) && isEqual(x.networkId, y.networkId)))
      .subscribe(async () => await Promise.all([this.setRegion(), this.setNetwork()]));

    this.setRegion();
    this.setNetwork();
  }

  public ngOnDestroy(): void {
    this.routeParamsSubscription?.unsubscribe();
    this.routeDataSubscription?.unsubscribe();
    this.settingsSubscription?.unsubscribe();
  }

  private async setRegion() {
    this.region = this.showRegionFilter && this.analysisSettings.regionId
      ? await this.regionsDataService.getById(this.analysisSettings.regionId)
      : undefined;
  }

  private async setNetwork() {
    this.network = this.showNetworkFilter && this.analysisSettings.networkId
      ? await this.appNetworksDataService.getById(this.appId, this.analysisSettings.networkId)
      : undefined;
  }

}
