import { Injectable } from '@angular/core';
import { cloneDeep, isEqual, union, without } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { EnumHelperService } from '../../shared/services/enum-helper.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { Perspective } from './perspective';

@Injectable({
  providedIn: 'root'
})
export class PinningService {

  private _pins: { [perspective: string]: string[] } = {};
  private readonly storageKey = 'pins';

  public constructor(
    private readonly enumHelper: EnumHelperService,
    private readonly localStorage: LocalStorageService
  ) {
    const pins = this.localStorage.get(this.storageKey) || {};
    enumHelper.getNames(Perspective).forEach(x => this._pins[x] = pins[x] || []);
    this._pins$ = new BehaviorSubject<{ [perspective: string]: string[] }>(cloneDeep(this._pins));
  }

  private _pins$: BehaviorSubject<{ [perspective: string]: string[] }>;

  public get pins$() { return this._pins$.asObservable(); }

  public pins(type: Perspective) { return this._pins[Perspective[type]] || []; }

  public pin(id: string, type: Perspective) {
    this._pins[Perspective[type]] = union(this._pins[Perspective[type]], [id]);
    this.update();
  }

  public unpin(id: string, type: Perspective) {
    this._pins[Perspective[type]] = without(this._pins[Perspective[type]], id);
    this.update();
  }

  public toggle(id: string, type: Perspective) {
    if (this.isPinned(id, type)) {
      this.unpin(id, type);
    } else {
      this.pin(id, type);
    }
  }

  public isPinned(id: string, type: Perspective) { return !!this._pins[Perspective[type]]?.includes(id); }

  public clear() {
    this._pins = {};
    this.enumHelper.getNames(Perspective).forEach(x => this._pins[x] = []);
    this.update();
  }

  public update() {
    if (isEqual(this._pins$.value, this._pins)) { return; }
    this.localStorage.set(this.storageKey, this._pins);
    this._pins$.next(cloneDeep(this._pins));
  }

}
